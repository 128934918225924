/* Last15Chart.css */

/* YOUR ORIGINAL ROOT VARIABLES */
:root {
  --chart-border-radius: 12px;
  --chart-height: 445.5px;
  --chart-padding-top: 8.75px;
  --slider-width: 262.5px;
  --slider-margin: 8.75px;
  --toggle-width: 131.25px;
  --switch-width: 43.75px;
  --switch-height: 24.5px;
  --switch-margin: 8.75px;
  --switch-border-radius: 24.5px;
  --switch-knob-size: 17.5px;
  --switch-knob-offset: 3.5px;
  --switch-knob-translate: 19.25px;
}

.chart-wrapper {
  width: 100%;
  height: var(--chart-height);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: var(--chart-border-radius);
  font-family: var(--font-main);
  overflow: hidden;
  position: relative;
  padding-top: var(--chart-padding-top);
  color: var(--dark-text-color);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
}

.player-chart-title {
  font-size: 21px;
  margin-left: 5px;
  margin-top: -2px;
  margin-bottom: 6px;
  font-weight: bold;
}

.player-toggle-container {
  display: flex;
  align-items: center;
  margin-right: 22px; /* moves the container left */
  margin-bottom: 4px;
}

.chart-container {
  width: 100%;
  height: calc(100% - 120px);
  flex-grow: 1;
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 6px;
  width: 100%;
}

#constant-line-slider {
  width: 250px;
  margin: 0 10px;
}

.slider-value {
  margin: 0 5px;
  font-size: 14px;
  font-weight: bold;
}

.slider-value input {
  width: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--medium-text-color);
}

.input-group {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.input-group button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: var(--medium-text-color);
  transition: color 0.3s ease;
}

.input-group button:hover {
  color: var(--dark-text-color);
}

.over-count {
  position: absolute;
  top: 45px;
  right: -25px;
  transform: translateX(-50%);
  font-size: 15px;
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: var(--switch-width);
  height: var(--switch-height);
  margin-left: var(--switch-margin);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider.round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-text-color);
  transition: 0.4s;
  border-radius: var(--switch-border-radius);
}

.slider.round:before {
  position: absolute;
  content: "";
  height: var(--switch-knob-size);
  width: var(--switch-knob-size);
  left: var(--switch-knob-offset);
  bottom: var(--switch-knob-offset);
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:checked + .slider:before {
  transform: translateX(var(--switch-knob-translate));
}


/* =============================================
   MOBILE-ONLY OVERRIDES
   ============================================= */

   @media (max-width: 550px) {
    .player-chart-title {
      font-size: 16px;
    }
  
    /* Adjust the toggle container to move it a bit more right */
    .player-toggle-container {
      margin-right: 12px;
    }
  
    /* Make the toggle smaller overall */

    .over-count {
      position: absolute;
      top: 43px;
      right: -39px;
      transform: translateX(-50%);
      font-size: 13px;
      font-weight: bold;
  }

  }
