.team-logs-historical-container {
  font-family: 'Montserrat';
  border-radius: 10.29px;
  padding: 20.29px;
  max-width: 1440px;
  margin: 20.58px 0;
  position: relative;
  transition: width 0.3s ease, max-width 0.3s ease, height 0.3s ease;
  background-color: white;
}

.filters-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
}

.filters-container label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--dark-text-color);
}

.filter-select,
.filter-input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--medium-text-color);
  font-size: 14px;
  margin-top: 5px;
}

.team-logs-historical-title {
  font-size: 24.86px;
  font-weight: bold;
  color: var(--dark-text-color);
  margin-top: -5px;
  margin-bottom: 15px;
}

.team-logs-table-container {
  max-height: 617.14px;
  overflow-y: auto;
}

.team-logs-historical-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.team-logs-historical-header,
.team-logs-historical-cell {
  border: none;
  padding: 12.34px 8.23px;
  text-align: left;
  white-space: nowrap;
}

.team-logs-historical-header {
  color: var(--medium-text-color);
  font-weight: bold;
  border-bottom: 2.06px solid var(--medium-text-color);
}

.team-logs-historical-cell {
  color: var(--dark-text-color);
  font-size: 15.46px;
  border-bottom: 1.03px solid var(--light-text-color);
}

.team-logs-historical-row:hover {
  background-color: var(--light-text-color);
}

.team-logs-historical-cell img.opponent-logo {
  width: 30.86px;
  height: 30.86px;
  border-radius: 50%;
  margin-right: 10.29px;
  vertical-align: middle;
}

/* Expanded row styles */
.expanded-row {
 
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--medium-text-color);
  font-size: 18px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Expanded content styles */
.expanded-content {
  padding: 15px;
  background-color: white;
  overflow-x: auto;
}

.expanded-content h4 {
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--dark-text-color);
  font-size: 18px;
}

/* Player stats table styles */
.player-stats-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  table-layout: auto;
}

.player-stats-table th,
.player-stats-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid var(--light-text-color);
  white-space: nowrap;
}

.player-stats-table th.sortable-header {
  cursor: pointer;
}

.player-stats-table th.sortable-header:hover {
  background-color: #f9f9f9;
}

.player-stats-table tbody tr:hover {
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

.player-name-cell {
  min-width: 100px;
  white-space: nowrap;
  overflow: visible;
}

/* Responsive styles */
@media screen and (max-width: 900px) {
  .team-logs-historical-container {
    max-width: 720px;
    padding: 8.23px;
    margin: 16.46px 0;
  }
  .team-logs-historical-title {
    font-size: 20.72px;
    margin-bottom: 8.23px;
  }
  .team-logs-table-container {
    max-height: 494.29px;
  }
  .team-logs-historical-header,
  .team-logs-historical-cell,
  .player-stats-table th,
  .player-stats-table td {
    padding: 9.87px 6.58px;
  }
  .team-logs-historical-cell,
  .player-stats-table {
    font-size: 12.37px;
  }
  .team-logs-historical-cell img.opponent-logo {
    width: 24.69px;
    height: 24.69px;
    margin-right: 8.23px;
  }
  .expanded-content h4 {
    font-size: 16px;
  }
  .player-name-cell {
    min-width: 80px;
  }
}

@media screen and (max-width: 600px) {
  .team-logs-historical-container {
    max-width: 480px;
    padding: 6.17px;
    margin: 12.35px 0;
  }
  .team-logs-historical-title {
    font-size: 16.58px;
    margin-bottom: 6.17px;
  }
  .team-logs-table-container {
    max-height: 370.72px;
  }
  .team-logs-historical-header,
  .team-logs-historical-cell,
  .player-stats-table th,
  .player-stats-table td {
    padding: 7.4px 4.94px;
  }
  .team-logs-historical-cell,
  .player-stats-table {
    font-size: 9.28px;
  }
  .team-logs-historical-cell img.opponent-logo {
    width: 18.52px;
    height: 18.52px;
    margin-right: 6.17px;
  }
  .expanded-content h4 {
    font-size: 14px;
  }
  .player-name-cell {
    min-width: 70px;
  }
}

@media screen and (max-width: 300px) {
  .team-logs-historical-container {
    max-width: 240px;
    padding: 4.11px;
    margin: 8.23px 0;
  }
  .team-logs-historical-title {
    font-size: 12.44px;
    margin-bottom: 4.11px;
  }
  .team-logs-table-container {
    max-height: 247.15px;
  }
  .team-logs-historical-header,
  .team-logs-historical-cell,
  .player-stats-table th,
  .player-stats-table td {
    padding: 4.94px 3.29px;
  }
  .team-logs-historical-cell,
  .player-stats-table {
    font-size: 6.19px;
  }
  .team-logs-historical-cell img.opponent-logo {
    width: 12.35px;
    height: 12.35px;
    margin-right: 4.11px;
  }
  .expanded-content h4 {
    font-size: 12px;
  }
  .player-name-cell {
    min-width: 50px;
  }
}
