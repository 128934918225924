/* PlayerPropsTable.css */

.player-props-container {
  width: 100%;
  max-height: 360px; /* Set the desired maximum height */
  overflow-y: auto;  /* Allows vertical scrolling if content exceeds max-height */
  overflow-x: auto;  /* Allows horizontal scrolling if the table is too wide */
  padding: 10px;
  box-sizing: border-box; /* Includes padding in width calculation */
  margin: 0; /* Removes default margin to prevent overflow issues */
}

.player-props-table {
  width: 100%;
  border-collapse: collapse;
  font-family: var(--font-roboto);
  /* If columns are too narrow, you can switch to auto.
     table-layout: fixed ensures the columns distribute equally,
     but also can cause overflow with big content. Try auto if needed:
        table-layout: auto;
  */
  table-layout: fixed;
}

.player-props-table th,
.player-props-table td {
  padding: 8px 5px;
  border-bottom: 1px solid var(--light-text-color);
  text-align: center;
  /* Ensures text wraps instead of overflowing cells */
  word-wrap: break-word;
  white-space: normal;
  /* Additional property for browsers to break long words anywhere */
  overflow-wrap: anywhere;
}

.player-props-table th {
  font-family: var(--font-main);
  color: var(--dark-text-color);
  cursor: pointer;
}

.player-props-table th.sort-ascending::after {
  content: ' ↑';
}

.player-props-table th.sort-descending::after {
  content: ' ↓';
}

.player-props-table td {
  color: var(--medium-text-color);
  font-family: var(--font-roboto);
}

.player-props-table tr:hover {
  background-color: #f9f9f9; /* Optional hover effect */
}

.player-props-table h2 {
  font-family: var(--font-main);
  color: var(--dark-text-color);
}

/* Responsive styling */

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .player-props-container {
    padding: 8px;
  }

  .player-props-table th,
  .player-props-table td {
    padding: 6px 4px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .player-props-container {
    padding: 5px;
  }

  /* 
    If you prefer not to force a minimum width, remove or adjust:
    .player-props-table {
      min-width: 600px;
    }
  */
  .player-props-table {
    min-width: 600px; /* Forces horizontal scroll if container < 600px */
  }

  .player-props-table th,
  .player-props-table td {
    padding: 5px 3px;
    font-size: 14px; /* Adjust font size for better readability */
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 400px) {
  .player-props-table th,
  .player-props-table td {
    padding: 4px 2px;
    font-size: 12px; /* Further adjust font size */
  }
}
