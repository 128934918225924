.i-d-marker {
  position: absolute;
  top: 50px;
  left: -65px;
  width: 53.75%; /* Adjusted width from 50% */
  height: 135.5%; /* Adjusted height from 220% */
  background-color: rgb(247,64,55); /* Change this to the desired color */
  transform: rotate(-45deg);
  transform-origin: top left;
  z-index: 1; /* Ensure the banner is above other content */
}

.monthly1,
.title4 {
  position: relative;
  letter-spacing: -0.02em;
}

.title4 {
  line-height: 22px;
  z-index: 1;
}

.monthly1 {
  line-height: 21px;
  font-weight: 700;
  display: inline-block;
  min-width: 41.25px; /* Adjusted min-width from 47px */
}

.arrow-drop-up-icon1 {
  width: 16.625px; /* Adjusted width from 19px */
  height: 16.625px; /* Adjusted height from 19px */
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  transform: rotate(-1deg);
}

.arrow-drop-up-wrapper {
  flex-direction: column;
  padding: var(--padding-12xs) 0 0;
}

.arrow-drop-up-wrapper,
.timeline-button-wrapper,
.timeline-button2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.timeline-button2 {
  flex-direction: row;
  z-index: 1;
}

.timeline-button-wrapper {
  flex-direction: column;
  padding: var(--padding-xs) 0 0;
  font-size: var(--caption-size);
  color: var(--color-lightsteelblue);
}

.i-d-content,
.i-d-header {
  display: flex;
  left: 0px;
}

.selected-player-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  border: 1px solid #ffffff;
  border-radius: 15px;
  margin-top: 10.5px; /* Adjusted margin-top from 20px */
  padding:10px;
  margin-right: 47.5px; /* Adjusted margin-right from 100px */
  margin-bottom: -15px;

}

.selected-player {
  display: flex;
  flex-direction: column; /* Stack first name and last name vertically */
  align-items: flex-start; /* Align items to the start (left) */
  
  z-index: 5;
}

.first-name, .last-name {
  font-size: 1em;
  text-align: left;
  z-index: 5;
}
.last-name {
  margin-top: 1.75px;
}

.selected-player {
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}

.dropdown-arrow {
  color: white;
  font-size: 14px;
}

.autocomplete-container {
  position: absolute;
  top: 40px;
  left: 0;
  margin: 0;
  width: 43.75%;
  border-radius: 18px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  padding: 2px 0;
  font-size: 90%;
  z-index: 999;
  max-height: 300px; /* Set a max-height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
}

.autocomplete-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 18px;
}

.autocomplete-item:hover,
.autocomplete-item.highlighted,
.autocomplete-item.clicked {
  background-color: var(--highlight-color);
  color: black;
}

.autocomplete-search-input {
  width: 100%;
  padding: 10px;
  border: none;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  font-size: 16px;
}

.autocomplete-search-input::placeholder {
  color: white;
}

.autocomplete-scroll-container {
  max-height: 175px; /* Adjusted max-height from 200px */
  overflow-y: auto;
  background: inherit; /* Inherit the background color from the parent */
}

./* Adjust the scrollbar styles for the .autocomplete-container */
.autocomplete-container::-webkit-scrollbar {
  width: 7px;
}

.autocomplete-container::-webkit-scrollbar-track {
  background: inherit;
}

.autocomplete-container::-webkit-scrollbar-thumb {
  background-color: var(--highlight-color);
  border-radius: 10px;
  border: 2px solid transparent;
}

.autocomplete-container::-webkit-scrollbar-thumb:hover {
  background-color: lightgray;
}

.i-d-header {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.i-d-content {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 var(--padding-4xs) 0 var(--padding-smi);
  box-sizing: border-box;
}

.minesota-1-icon1 {
  position: absolute;
  top: -70px; /* Adjusted top from -80px */
  right: 4px;
  width: 105px; /* Adjusted width from 120px */
  height: 105px; /* Adjusted height from 120px */
  object-fit: contain;
}

.minesota-1-group {
  height: 170.625px; /* Adjusted height from 203px */
  flex: 1;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fotor-bg-remover-2024042919351-icon1 {
  max-height: 90%;
  max-width: 90%;
  object-fit: cover;
  position: center;
  margin-right: 20.625px; /* Adjusted margin-right from 35px */
  margin-top: 17.5px; /* Adjusted margin-top from -20px */
  z-index: 15;
}

.monthly-filter {
  width: 291.25px; /* Adjusted width from 333px */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  box-sizing: border-box;
  max-width: 100%;
}

.separator1 {
  height: 0px;
  width: 0px;
  position: absolute;
  margin: 0 !important;
  background-color: var(--i-d-marker-bg-color);
  top: 12.25px; /* Adjusted top from 14px */
  left: 141.75px; /* Adjusted left from 162px */
  border-right: 1px solid var(--secondary-grey-300);
  box-sizing: border-box;
}

.fg-percentage1 {
  height: 8.75px; /* Adjusted height from 10px */
  position: relative;
  letter-spacing: -0.02em;
  line-height: 17.5px; /* Adjusted line-height from 20px */
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-width: 73.5px; /* Adjusted min-width from 84px */
}

.stat-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 14px;
  margin-left: -10px;
  padding: 0 0 var(--padding-10xs);
}

.percentage-stat-child {
  height: 17px; /* Adjusted height from 8px */
  width: 7px; /* Adjusted width from 8px */
  position: relative;
  z-index: 1;
  margin-left: -63px; /* Adjusted margin-left from -72px */
}

.percentage-stat {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.percentage-value1 {
  position: relative;
  margin-top: 4.375px; /* Adjusted margin-top from 5px */
  letter-spacing: -0.02em;
  line-height: 14px; /* Adjusted line-height from 16px */
  font-size: 19px; /* Adjusted font-size from 20px */
  font-weight: bold;
  display: inline-block;
  min-width: 35.875px; /* Adjusted min-width from 41px */
  color: var(--team-color);
}

.stat-value {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-2xl) 0 var(--padding-3xl);
  font-size: var(--font-size-lg);
  color: var(--color-darkslateblue-100);
}

.percentage-value,
.sent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sent1 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}

.percentage-value {
  width: 73.5px; /* Adjusted width from 84px */
  justify-content: flex-end;
  padding: 0 0 var(--padding-9xs);
  box-sizing: border-box;
}

/* Adding classes to differentiate left and right stats */
.stat-item-left {
  color: var(--team-color);
}

.stat-item-right {
  color: white;
}

/* Moving percentage value below recent scoring trend */
.stats-container-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}

.stats-container-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}

/* Adjusting styles for the left side stats */
.label6 {
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: -0.02em;
  line-height: 21px; /* Adjusted line-height from 24px */
  font-weight: 500;
  font-size: 14px;
  color: var(--team-color); /* Set color to team color */
}

.percentage-value1 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 14px; /* Adjusted line-height from 16px */
  font-weight: 700;
  display: inline-block;
  min-width: 35.875px; /* Adjusted min-width from 41px */
  color: var(--team-color); /* Set color to team color */
}

/* Adjusting styles for the right side stats */
.mpg1 {
  flex: 1;
  position: relative;
  letter-spacing: -0.0em;
  line-height: 9.75px; /* Adjusted line-height from 10px */
  font-weight: bold;
  font: var(--font-main);
  font-size: 14px;
  padding: 0px 4px;
  color: white; /* Set color to white */
}

.m-p-g {
  width: 35.875px; /* Adjusted width from 41px */
  height: 12px; /* Adjusted height from 16px */
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: -0.02em;
  line-height: 20.25px; /* Adjusted line-height from 30px */
  font-weight: 700;
  margin-left: 3px;

  color: white; /* Set color to white */
}

.recieved1 {
  width: 49.875px; /* Adjusted width from 57px */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}

.value {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  margin-left: 4.375px; /* Adjusted margin-left from 5px */
  line-height: 20.125px; /* Adjusted line-height from 23px */
  font-weight: 900;
  font-size: 15px;
  left: 0px;
}

.value-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-6xs) 0 0;
  text-align: center;
  margin-left: -10px;
  font-size: var(--font-size-sm);
  color: var(--color-mediumseagreen);
}

.received-item {
  position: absolute;
  top: 0;
  left: 3.5px; /* Adjusted left from 4px */
  width: 63.875px; /* Adjusted width from 73px */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}

.vector-icon1 {
  position: absolute;
  top: 43.75px; /* Adjusted top from 50px */
  left: 0;
  width: 7.35px; /* Adjusted width from 8.4px */
  height: 4.375px; /* Adjusted height from 5px */
}

.m-p-g-value {
  align-self: stretch;
  height: 53.375px; /* Adjusted height from 61px */
  position: relative;
  margin-left: 10px;
}

.m-p-g-value-wrapper {
  width: 67.375px; /* Adjusted width from 77px */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
}

.content1,
.player-id2,
.player-id3 {
  background-color: var(--color-white);
  overflow: hidden;
  display: flex;
  align-self: center;
  align-items: center; /* Ensure the content is centered vertically */
  justify-content: center; /* Ensure the content is centered horizontally */
}

.content1 {
  background-color: var(--i-d-marker-bg-color); /* Inherit the background color from .i-d-marker */
  align-self: center;
  width: 80%;
  box-shadow: 0 18px 40px rgba(0, 0, 0, 0.548);
  border-radius: 6px;
  border-color: black;
  border: 1px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2px 20px;
  position: relative;
  gap: 17.5px; /* Adjusted gap from 20px */
  margin-bottom: 50px;
  z-index: 1;
  font-size: var(--caption-size);
  color: var(--color-lightsteelblue);
}

.player-id2,
.player-id3 {

  justify-content: flex-start;
  max-width: 100%;
}

.player-id3 {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  padding: 17.5px 1.75px; /* Adjusted padding from 20px 2px */
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-11xs-5);
  flex-shrink: 0;
}

.player-id2 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-main);
}
