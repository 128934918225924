.matchup-players-roster-outer-container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 20px 0px;
  box-sizing: border-box;
  position: relative;
}

.matchup-players-roster-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
  position: relative;
  height: 40px;  /* Adjust as needed */
}

.matchup-players-roster-team-labels {
  display: flex;
  gap: 10px;
}

.matchup-players-roster-column-label {
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  background-color: #1a365d;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 0.9em;
  position: absolute;
  z-index: 10;
}

.home_sub, .home_sub2, .home_starter,
.away_sub, .away_sub2, .away_starter, .roster {
  position: absolute;
  /* You can adjust these positions as needed */
  top: 0;
  font-family: var(--font-main);
  padding:10px;
  font-size: 16px;
}

.home_sub { left: 140px; top:40px }
.home_starter { left: 410px; top: 40px;}
.roster { 
  left: 50%; 
  transform: translateX(-50%);
  top:60px; padding:15px;
  font-size:20px;

  }
.away_starter { right: 370px; top:30px}
.away_sub { right: 95px; top:40px }
.away_sub2 { right: 60px; top:40px }

.matchup-players-roster-container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #eff2f5;
  padding-top: 20px;
}

.matchup-players-roster-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e2e8f0;
  position: relative;
}

.matchup-players-roster-row::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 8px;  /* Increased height for wider separator */
  background-color: var(--light-text-color)
}

.matchup-players-roster-team {
  display: flex;
  flex: 3;
  gap: 15px;
}

.matchup-players-roster-position-label {
  width: 20px;
  font-weight: bold;
  color: #1a365d;
  text-align: center;
  padding: 10px 0;
}

.matchup-players-roster-separator {
  width: 3px;  /* Increased width for more visible separator */
  background-color: #e2e8f0;
  align-self: stretch;
  margin: 0 20px;
}

/* Ensure player cards maintain full size */
.matchup-players-roster-team > * {
  width: 100%;
  height: auto;
}