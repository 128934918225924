/* Injury Report CSS */
.injury-report-container {
  display: flex;
  flex-direction: column;
  font-family: var(--font-roboto);
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
  color: var(--dark-text-color);

  /* Original normal mode styling */
  padding-bottom: 48.5px;
  height: 274.25px;
}

.title {
  flex-grow: 1;
  font-size: 25.2px;
  font-weight: bold;
  text-align: center;
  color: var(--dark-text-color);
  font-family: var(--font-main);
}

.table-header {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
  font-family: var(--font-main);
  color: var(--medium-text-color);
}

.injury-logo {
  width: 68px;
  height: 68px;
  margin-right: 12.1px;
  margin-top: 5px;
  margin-left: 8.7px;
}

.selected-team-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-right: 36.4px;
  z-index: 2;
  color: var(--medium-text-color);
}

.selected-team {
  font-size: 14.5px;
  font-weight: bold;
  margin-right: 4.5px;
}

.dropdown-arrow-injury-report {
  margin-left: 4.5px;
  display: inline-block;
}

.autocomplete-injury-table-container {
  position: absolute;
  top: 100%;
  right: 0;
  width: 137.5px;
  margin-top: 0px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3.1px;
  box-shadow: 0 2.1px 12.6px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.autocomplete-injury-table-item {
  padding: 10.5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.autocomplete-injury-table-item:hover,
.autocomplete-injury-table-item.highlighted,
.autocomplete-injury-table-item.clicked {
  background-color: rgb(107, 63, 63);
  color: black;
}

.table-container {
  flex-grow: 1;
  height: 100px; /* Desktop fixed height */
  overflow-y: auto;
  overflow-x: auto; /* <-- Added for horizontal scroll if table is too wide */
}

.injury-report-table {
  width: 100%;
  border-collapse: collapse;
}

.injury-report-table th,
.injury-report-table td {
  padding: 10px 14px;
  text-align: left;
  white-space: nowrap;
}

.injury-report-table td {
  font-size: 18px;
  color: var(--medium-text-color);
}

.injury-report-table thead th {
  color: var(--dark-text-color);
  font-weight: bold;
  background-color: inherit;
  padding: 14px;
  border-bottom: 2.1px solid var(--medium-text-color);
  font-size: 19px;
  font-family: var(--font-main);
}

.injury-report-table tbody td {
  font-family: var(--font-roboto);
}

.injury-report-table tbody tr:nth-child(even) {
  background-color: rgba(151, 152, 153, 0.1);
  color: var(--medium-text-color);
}

.injury-report-table tbody tr:nth-child(odd) {
  background-color: rgb(255, 254, 254);
  color: var(--team-color);
}

.status-indicator {
  display: inline-block;
  width: 9.1px;
  height: 9.1px;
  border-radius: 50%;
  margin-right: 7.3px;
}

.status-day-to-day {
  background-color: rgb(255, 209, 5);
}

.status-out {
  background-color: rgb(247,64,55);
}

/* Responsive overrides for mobile */
@media (max-width: 600px) {
  .injury-report-container {
    /* Remove forced height/padding in mobile mode */
    height: auto !important;
    padding-bottom: 0 !important;
    /* No forced max-height so table fits fully */
    max-height: none !important; 
  }

  .table-container {
    height: auto !important;   /* Let table grow naturally */
    overflow-y: visible !important;
    overflow-x: auto !important; /* ensure horizontal scroll on mobile too */
  }

  .title {
    font-size: 20px; 
    margin-top: 0.5em;
  }

  .injury-logo {
    width: 50px;
    height: 50px;
    margin-right: 8px;
    margin-left: 6px;
  }

  .selected-team {
    font-size: 12px; 
  }

  .injury-report-table td {
    font-size: 16px;
  }

  .injury-report-table thead th {
    font-size: 18px;
  }
}
