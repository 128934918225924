.shooting-vs-defense-radar {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .shooting-vs-defense-chart-title {
    text-align: left;
    margin-left:20px;
    font-family: var(--font-main);
    color: var(--dark-text-color);
    font-size:20px;
    margin-bottom: 20px;
    font-weight: 650;
  }
  
  .custom-tooltip {
    font-family: var(--font-main);
    color: #333;
    line-height: 1.5;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .custom-tooltip strong {
    font-size: 16px;
    font-weight: 600;
  }
  
  .recharts-default-legend {
    font-family: var(--font-main);
    color: #333;
  }
  
  .recharts-text {
    font-family: var(--font-main);
  }
  