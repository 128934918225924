/* Center the table in the middle of the page */
.player-feature-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically if needed */
  padding: 20px;
  box-sizing: border-box;
  color: #f8f2fc;
  font-family: var(--font-main);
}

/* Add horizontal scrolling for smaller screens */
.table-wrapper {
  width: 100%;
  max-width: 1200px; /* Set max-width to 1200px */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  margin: 0 auto; /* Center the table within the container */
}

/* Player Feature Table Styles */
.player-feature-table {
  width: 100%;
  border-collapse: collapse;
  background: #ffffff;
  border-radius: 12px;
  
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.player-feature-table th,
.player-feature-table td {
  padding: 12px 15px;
  font-size: 15px;
  border-bottom: 1px solid #ddd;
  font-family: var(--font-roboto);
}
.player-feature-table td {
  color:var(--dark-text-color);
}
.player-feature-table th {
  background-color: var(--main-color);
  color: white;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: var(--font-main);
}

.player-feature-table th:hover {
  background-color: #1ab3ac;
}

/* Ascending and descending indicators */
.player-feature-table th.asc::after {
  content: " 🔼";
  font-size: 12px;
  margin-left: 5px;
}

.player-feature-table th.desc::after {
  content: " 🔽";
  font-size: 12px;
  margin-left: 5px;
}

/* Table rows */
.player-feature-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.player-feature-table tr:nth-child(odd) {
  background-color: #fefefe;
}

.player-feature-table tr:hover {
  background-color: #f1f1f1;
  transition: background-color 0.3s ease;
}

/* Filter input styles based on PredictionResultsTable.css */
.projection-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.projection-filters input,
.projection-filters select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  transition: all 0.3s ease;
  font-family: var(--font-roboto);
}

.projection-filters input:focus,
.projection-filters select:focus {
  border-color: var(--detail-color);
  box-shadow: 0 0 5px rgba(76, 161, 175, 0.5);
}

/* Center the heading */
.player-feature-table-container h1 {
  font-size: 24px;
  color: var(--main-color);
  font-weight: 650;
  margin-bottom: 20px;
  font-family: var(--font-main);
}
.full-name {
  display: inline;
}

.mobile-name {
  display: none;
}

/* Multi-select dropdown for Features */
.filter-group {
  position: relative;
  display: inline-block;
}

/* We want the default filter-group styling to remain for desktop.
   On mobile, we will split the filters into two rows by assigning extra classes. */
.multi-select-control {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 12px;
  cursor: pointer;
  background-color: #ffffff;
  min-width: 150px;
  color: var(--main-color);
}

.multi-select-arrow {
  float: right;
  margin-left: 10px;
  border: solid #333;
  border-width: 0 1px 1px 0;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.multi-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow-y: auto;
  z-index: 10;
}

.option {
  padding: 8px 12px;
  text-align: left;
  cursor: pointer;
}

.option label {
  cursor: pointer;
}

.option:hover {
  background-color: #f1f1f1;
}

/* MOBILE SPECIFIC STYLING */
/* Arrange filters in two rows on mobile:
   Row 1: featureProbability and feature (multi-select)
   Row 2: playerName, teamAbbreviation, opponent */
@media (max-width: 768px) {
  .player-feature-table-container {
    padding: 10px;
  }

  .player-feature-table {
    font-size: 12px;
    width: 100%;
  }

  .player-feature-table th:nth-child(2),
  .player-feature-table th:nth-child(5),
  .player-feature-table th:nth-child(7),
  .player-feature-table th:nth-child(10),
  .player-feature-table th:nth-child(11),
  .player-feature-table td:nth-child(2),
  .player-feature-table td:nth-child(5),
  .player-feature-table td:nth-child(7),
  .player-feature-table td:nth-child(10),
  .player-feature-table td:nth-child(11) {
    display: none;
  }

  .player-feature-table th {
    padding: 6px 7px;
    font-size: 10px;
  }

  .player-feature-table td {
    padding: 6px 8px;
    font-size: 11px;
  }

  .player-feature-table th,
  .player-feature-table td {
    word-wrap: break-word;
    white-space: nowrap;
  }

  .projection-filters {
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 15px;
    justify-content: center;
  }

  /* For mobile, we split filters into two rows using extra classes */
  .filter-group {
    width: 100%;
    max-width: 170px;
  }
  /* Row 1: featureProbability and feature */
  .filter-row1 {
    order: 1;
    flex: 1 1 45%;
  }
  /* Row 2: playerName, teamAbbreviation, opponent */
  .filter-row2 {
    order: 2;
    flex: 1 1 100%;
  }

  .projection-filters input,
  .projection-filters select {
    width: 100%;
    font-size: 12px;
    padding: 6px;
  }

  .full-name {
    display: none;
  }

  .mobile-name {
    display: inline;
  }
}
