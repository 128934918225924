/* Navigation Menu Styling */
.nav-menu-navigation-menu {
  position: sticky;
  top: 0;
  z-index: 100000;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out;
}

.nav-menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0.5rem 1rem; 
  height: 60px;
  overflow: visible;
}

/* Logo */
.nav-menu-logo {
  height: 100%;
  max-height: 110px;
  width: auto;
  margin-left:0px;
  margin-top: 5px;
  margin-right: 0.75rem;
  object-fit: contain;
}

/* Links container */
.nav-menu-links {
  display: flex;
  align-items: center;
  color: #3b3b3b;
}

/* Right container if user not logged in => we place login button there */
.nav-menu-right {
  display: flex;
  align-items: center;
}

/* If user is logged in -> we have normal nav links, if not -> only login button */

/* Highlighted link (Top Picks) */
.nav-menu-highlighted {
  font-weight: 700;
  color: var(--main-color) !important;
  margin-right: 2rem;
}

/* Common link styling */
.nav-menu-link {
  margin-right: 2rem;
  color: #3b3b3b;
  text-decoration: none;
  font-weight: 550;
  font-family: var(--font-main);
  font-size: 1rem;
  position: relative;
  transition: color 0.3s ease-in-out;
}
.nav-menu-link:last-child {
  margin-right: 0; 
}

.nav-menu-link:hover {
  color: var(--main-color);
}

/* If it's a button (for login) */
.nav-menu-link-button {
  margin-right: 2rem;
  color: white;
  background-color: var(--main-color, #0066cc);
  border: none;
  padding: 8px 12px;
  text-decoration: none;
  font-weight: 550;
  font-family: var(--font-main);
  font-size: 1rem;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.nav-menu-link-button:hover {
  background-color: #0052a3;
}

/* Dropdown styling */
.nav-menu-dropdown {
  cursor: pointer;
  position: relative;
}

.nav-menu-dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  top: 100%;
  left: 0;
  min-width: 180px;
  padding: 10px 0;
  border-radius: 8px;
}

.nav-menu-dropdown:hover .nav-menu-dropdown-content {
  display: block;
}

/* Each dropdown item */
.nav-menu-dropdown-item {
  padding: 12px 16px;
  color: #4a5568;
  text-decoration: none;
  display: block;
  font-size: 0.9rem;
  transition: background-color 0.3s ease-in-out;
}
.nav-menu-dropdown-item:hover {
  background-color: #f7f7f7;
  color: var(--main-color);
}

/* User menu */
.nav-menu-user-menu {
  position: relative;
}
.nav-menu-user-menu:hover .nav-menu-dropdown-content {
  display: block;
}
/* Container for user info */
.nav-menu-user-info {
  padding: 12px 16px;
  color: #4a5568;
  position: relative; /* Allows dropdown to position relative to this container */
}

/* Style for the logout link */
.nav-menu-logout-link {
  display: block;
  cursor: pointer;
  color: #007BFF;
  font-weight: bold;
  margin-bottom: 10px;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.nav-menu-logout-link:hover {
  color: #0056b3;
}

/* Dropdown Wrapper */
.nav-menu-dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Trigger (More Options) */
.nav-menu-dropdown-trigger {
  cursor: pointer;
  color: #007BFF;
  text-decoration: underline;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-menu-dropdown-trigger:hover {
  color: #0056b3;
}

/* Dropdown Content */
.nav-menu-dropdown-content2 {
  display: none; /* Hidden by default */
  position: absolute;
  left: 0;
  top: 30px; /* Adjust to position below the trigger */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  z-index: 100;
}

/* Make the dropdown visible when hovering over the parent */
.nav-menu-dropdown:hover .nav-menu-dropdown-content {
  display: block;
}

/* Dropdown Link (Manage Subscription) */
.nav-menu-subscription-link {
  display: block;
  cursor: pointer;
  color: #007BFF;
  font-weight: bold;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.nav-menu-subscription-link:hover {
  color: #0056b3;
}

.nav-menu-logout-link {
  color: #e53e3e;
  cursor: pointer;
  text-decoration: none;
}
.nav-menu-logout-link:hover {
  text-decoration: underline;
}

/* Preferences (gear icon) */
.nav-menu-preferences {
  position: relative;
}
.nav-menu-preferences:hover .nav-menu-preferences-content {
  display: block;
}

/* Toggles for colorblind & american odds */
.nav-menu-colorblind-switch,
.nav-menu-american-odds-switch {
  display: flex;
  align-items: center;
  margin: 8px 16px;
}

/* Toggle switch styling */
.nav-menu-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-right: 8px;
}
.nav-menu-switch input {
  display: none;
}
.nav-menu-slider {
  position: absolute;
  cursor: pointer;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}
.nav-menu-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: white;
  transition: 0.4s;
  left: 4px;
  bottom: 4px;
}
.nav-menu-switch input:checked + .nav-menu-slider {
  background-color: #1a73e8;
}
.nav-menu-switch input:focus + .nav-menu-slider {
  box-shadow: 0 0 1px #1a73e8;
}
.nav-menu-switch input:checked + .nav-menu-slider:before {
  transform: translateX(16px);
}

/* -------------- MOBILE -------------- */
/* Hamburger icon => hidden by default, show only on mobile */
.nav-menu-hamburger {
  display: none; /* hidden on desktop */
  font-size: 24px;
  cursor: pointer;
  color: #333;
  margin-left: auto; /* push it to the right side */
}

/* For the mobile open/close effect => we add a class to .nav-menu-links */
.nav-menu-links-mobile-open {
  display: flex !important; /* or block if you prefer stacked links */
  flex-direction: column;
  background-color: #ffffff;
  position: absolute;
  top: 60px; /* just below the nav */
  left: 0;
  width: 100%;
  padding: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

@media (max-width: 768px) {
  .nav-menu-hamburger {
    display: block; /* show hamburger */
  }
  .nav-menu-logo {
    margin-left: 10px;
    width:90%;
    height: 90%;
  }

  /* Hide normal links unless we open the mobile menu */
  .nav-menu-links {
    display: none; /* hidden by default on mobile */
  }

  /* If user is not logged in => the .nav-menu-right has the login button on the right */
  .nav-menu-right {
    margin-left: auto;
  }
  
  /* If we open nav-menu-links => we show them with .nav-menu-links-mobile-open */
  .nav-menu-links.nav-menu-links-mobile-open {
    display: flex;
  }

  /* Nav links in mobile become stacked */
  .nav-menu-links.nav-menu-links-mobile-open .nav-menu-link {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .nav-menu-dropdown-content {
    position: static;
    box-shadow: none;
    margin-bottom: 1rem;
  }

  .nav-menu-dropdown:hover .nav-menu-dropdown-content {
    display: block; /* let it show on mobile when clicked/tapped */
  }
}
