.matchup-players-predictions {
    width: 70%;
    padding: 6px;
}

.matchup-players-predictions-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 7px;
    
    margin-left:7px;
    text-align: center;
    color: #333;
    font-family: var(--font-main); /* Font for titles/headers */
}

.matchup-players-predictions-table {
    width: 120%;
    font-size: 12.5px;
    border-collapse: collapse;
    
    font-family: var(--font-roboto); /* Font for table contents */
}

.matchup-players-predictions-table th,
.matchup-players-predictions-table td {
    padding: 1px 13px;
    
}

.matchup-players-predictions-table th {
    color: #666;
    text-align: left;
    padding-bottom: 1px; /* Reduce space between text and border */
    padding-top: 2px; /* Add space above the header text */
    font-family: var(--font-main); /* Font for table headers */
    border-bottom: 1px solid var(--light-text-color); /* Keeps the underline for the headers */
}



.matchup-players-predictions-table td {
    padding-top:1.5px;
    color: #333;
    text-align: center;
}

.matchup-players-lock-icon {
    width: 11px;
    height: 11px;
    background-color: #fdb927;
    border-radius: 2px;
}