.team-dashboard-container {
  padding: 0rem 2rem 4rem;
}

.team-dashboard-content {
  text-align: center;
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 20px;
}

.team-dashboard-row,
.team-dashboard-row-higher {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: stretch;
  gap: 20px;
}

.team-dashboard-row-higher {
  margin-top: 20px;
}

.team-dashboard-square-container {
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  flex: 1 1 288px;
  border-radius: 14px;
  box-shadow: 3px 3px 6px rgba(5, 121, 141, 0.1);
  border: 1px solid #ccc;
  height: 325px;
  transition: background-color 0.5s ease, all 0.5s ease;
  max-width: 312px;
}

.team-dashboard-tonights-game-container {
  background-color: rgb(218, 228, 230);
  margin-bottom: 50px;
  max-width: 875px;
  height: 100%;
  border-radius: 14px;
  box-shadow: 3px 3px 6px rgba(22, 61, 68, 0.1);
  border: 1px solid #ccc;
  flex: 1 1 612px;
  transition: all 0.5s ease;
}

.team-dashboard-feature-menu {
  display: flex;
  gap: 15px;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  transition: all 0.3s ease;
  margin-bottom: 20px;
  margin-top: -25px;
}


.team-dashboard-feature-item {
  flex: 1 1 90px;
  background-color: white;
  height: 45px;
  border: 2px solid #D0D0D0;
  border-radius: 25px;
  transition: box-shadow 0.3s ease, all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: var(--font-main);
  font-weight: bold;
  font-size: 16px;
  color: var(--dark-text-color);
}
.team-dashboard-feature-item.active {
  background-color: #ececec; /* Highlight the active button */
  color: var(--dark-text-color);/* Change text color for better contrast */
  transform: scale(1.1); /* Slightly larger size for active button */
  font-weight: 650; /* Make text bolder */
}
.team-dashboard-feature-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #f0f0f0;
}

.team-points-drilldown {
  background-color: rgb(255, 255, 255);
  max-height: 450px;
  min-height: 450px;
  border-radius: 14px;
  box-shadow: 1px 1px 6px rgba(22, 61, 68, 0.1);
  border: 1px solid #ccc;
  flex: 4 1 720px;
  max-width: 1105px;
  transition: all 0.5s ease;
}

.team-stats-table {
  max-width: 335px;
  max-height: 450px;
  min-height: 450px;
  border: 1px solid #ccc;
  border-radius: 14px;
}

.team-dashboard-versus-team-chart {
  background-color: rgb(255, 255, 255);
  max-width: 550px;
  height: 360px;
  border-radius: 14px;
  box-shadow: 3px 3px 6px rgba(22, 61, 68, 0.1);
  border: 1px solid #ccc;
  flex: 1 1 470px;
  transition: all 0.5s ease;
}

.team-dashboard-player-share {
  width: 910px;
  border-radius: 14px;
  box-shadow: 3px 3px 6px rgba(22, 61, 68, 0.1);
  border: 1px solid #ccc;
}

.team-logs-historical-dashboard-container {
  background-color: rgb(255, 255, 255);
  border-radius: 14px;
  flex: 1 1 1440px;
  max-width: 1440px;
  transition: all 0.3s ease;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.similar-team-performance {
  background-color: rgb(255, 255, 255);
  border-radius: 14px;
  flex: 1 1 350px;
  max-width: 350px;
  transition: all 0.3s ease;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  box-sizing: border-box;
}

.team-dashboard-opponent-defense {
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  flex: 1 1 500px;
  border-radius: 20.6px;
  border: 1px solid #ddd;
  box-shadow: 0 2.1px 4.1px rgba(0, 0, 0, 0.1);
  height: 360px;
  transition: background-color 0.5s ease, all 0.5s ease;
  max-width: 719px;
  width: 100%;
}

.team-dashboard-defense-table {
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  flex: 1 1 450px;
  border-radius: 20.6px;
  border: 1px solid #ddd;
  box-shadow: 0 2.1px 4.1px rgba(0, 0, 0, 0.1);
  height: 475px;
  transition: background-color 0.5s ease, all 0.5s ease;
  max-width: 450px;
  width: 100%;
}

.square-container-bottom-wider-table {
  /* Add styles if needed */
}

@media (max-width: 900px) {
  .team-dashboard-row,
  .team-dashboard-row-higher {
    flex-wrap: wrap;
  }
  .team-dashboard-square-container {
    flex-basis: 100%;
  }
  .team-dashboard-tonights-game-container {
    max-width: 612px;
  }
  .team-dashboard-feature-menu {
    justify-content: center;
  }
  .team-dashboard-feature-item {
    flex-basis: 80px;
  }
}

@media (max-width: 600px) {
  .team-dashboard-row,
  .team-dashboard-row-higher {
    flex-wrap: wrap;
  }
  .team-dashboard-square-container {
    flex-basis: 100%;
  }
  .team-dashboard-tonights-game-container {
    max-width: 438px;
  }
  .team-points-drilldown {
    max-width: 720px;
  }
  .team-dashboard-feature-item {
    flex-basis: 70px;
    font-size: 14px;
  }
}

@media (max-width: 300px) {
  .team-dashboard-row,
  .team-dashboard-row-higher {
    flex-direction: column;
  }
  .team-dashboard-square-container {
    flex: 1 1 100%;
  }
  .team-dashboard-tonights-game-container {
    max-width: 263px;
  }
  .team-points-drilldown {
    max-width: 263px;
  }
  .team-dashboard-feature-menu {
    flex-direction: column;
    align-items: center;
  }
  .team-dashboard-feature-item {
    width: 100%;
    max-width: 135px;
  }
}