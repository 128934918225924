/* ============== Desktop Styling (unchanged) ============== */
.player-dashboard-container {
  text-align: center;
  max-width: 1440px;
  min-width: 1440px; /* pinned width for normal desktop mode */
  margin: 0 auto;
  padding-top: 15px;
}

.player-dashboard-row {
  display: flex;
  gap: 15px;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  transition: all 0.3s ease;
}

.player-dashboard-player-card,
.player-dashboard-team-versus {
  background-color: white;
  overflow: hidden;
  flex: 1 1 250px;
  border-radius: 14px;
  box-shadow: 3px 3px 6px rgba(5, 121, 141, 0.1);
  border: 1px solid #ccc;
  height: 325px;
  transition: background-color 0.5s ease, all 0.5s ease;
  max-width: 320px;
}

.player-dashboard-injury-report {
  background-color: white;
  margin-bottom: 51px;
  width: 100%; /* Take full width by default */
  height: 100%;
  border-radius: 14px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 500px;
  transition: all 0.3s ease;
  overflow-x: auto; /* allow horizontal scroll if content is too wide */
}

.player-dashboard-feature-menu {
  display: flex;
  gap: 15px;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  transition: all 0.3s ease;
  margin-bottom: 20px;
  margin-top: -25px;
}

.player-dashboard-feature-item {
  flex: 1 1 90px;
  background-color: white;
  height: 45px;
  border: 2px solid #D0D0D0;
  border-radius: 25px;
  transition: box-shadow 0.3s ease, all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 5px;
  font-family: var(--font-main);
  font-weight: bold;
  font-size: 16px;
  color: var(--medium-text-color);
  position: relative;
}

.player-dashboard-feature-item .threshold-value {
  font-size: 12px;
  color: #888;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}

.player-dashboard-feature-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #f0f0f0;
}
.player-dashboard-feature-item.active {
  background-color: #ececec;
  color: var(--dark-text-color);
  transform: scale(1.1);
  font-weight: 650;
}

.player-dashboard-main-charts {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  transition: all 0.3s ease;
}

.player-dashboard-last-15 {
  background-color: white;
  padding: 1rem 0;
  max-height: 460px;
  border-radius: 14px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 4 1 750px;
  max-width: 1070px;
  transition: all 0.3s ease;
}

.player-dashboard-last-15-table {
  flex: 1 1 300px;
  border-radius: 14px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 345px;
  transition: all 0.3s ease;
}

.player-dashboard-versus-team,
.player-dashboard-shooting,
.player-dashboard-minutes,
.player-dashboard-shooting-defense {
  background-color: white;
  overflow: hidden;
  flex: 1 1 300px;
  min-width: 300px;
  max-width: 376px;
  border-radius: 14px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 350px;
  transition: background-color 0.5s ease, all 0.5s ease;
  margin-bottom: 21px;
}

.player-dashboard-props-table {
  background-color: white;
  flex: 1 1 300px;
  border-radius: 14px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s ease, all 0.5s ease;
}

.player-dashboard-opponent-defense {
  background-color: white;
  overflow: hidden;
  flex: 3 1 550px;
  border-radius: 14px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 360px;
  transition: background-color 0.5s ease, all 0.5s ease;
  max-width: 770px;
}

.player-dashboard-position-defense {
  background-color: white;
  overflow: hidden;
  flex: 1 1 151px;
  border-radius: 14px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 350px;
  transition: background-color 0.5s ease, all 0.5s ease;
  max-width: 180px;
}

.player-dashboard-premium-feature {
  background-color: white;
  overflow: hidden;
  flex: 1 1 334px;
  border-radius: 14px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 350px;
  transition: background-color 0.5s ease, all 0.5s ease;
  max-width: 569px;
}

.player-dashboard-historical-logs {
  background-color: white;
  border-radius: 14px;
  margin-top: 21px;
  flex: 2 1 906px;
  max-width: 950px;
  transition: all 0.3s ease;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.player-dashboard-similar-performance {
  background-color: white;
  border-radius: 14px;
  flex: 1 1 300px;
  margin-top: 21px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  transition: all 0.3s ease;
  padding: 10px;
  box-sizing: border-box;
}

.player-dashboard-player-selection {
  max-width: 0px; /* Default for desktop remains the same */
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.player-dashboard-chart-wrapper {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 14px;
  font-family: var(--font-main);
  overflow: hidden;
  height: 514px;
  position: relative;
}

.player-dashboard-chart-title {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.player-dashboard-chart-container {
  width: 100%;
  height: 381px;
}

.player-dashboard-toggle-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 135px;
}

.player-dashboard-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 26px;
  margin-right: 10px;
}

.player-dashboard-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.player-dashboard-slider {
  position: absolute;
  cursor: pointer;
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 14px;
}
.player-dashboard-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px; 
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 14px;
}
input:checked + .player-dashboard-slider {
  background-color: var(--primary-color);
}
input:checked + .player-dashboard-slider:before {
  transform: translateX(20px);
}

.player-dashboard-over-count {
  position: absolute;
  top: 41px;
  right: 21px;
  font-size: 16px;
  font-weight: bold;
}

/* 
   ========== Keep Desktop (above 1100px) + Mobile (768px & below, 576px, 480px) 
   ========== Remove older breakpoints that conflict (1200px, 992px, 900px)
   ========== Insert new 1100px layout reorder
*/

/* For screens narrower than 1440px (keep pinned container removed) */
@media (max-width: 1440px) {
  .player-dashboard-container {
    max-width: 100%;
    min-width: auto;
    margin: 0 auto;
    padding: 0 15px;
  }
}

/* NEW: For screens up to 1100px wide 
   (this covers ~iPad around 1050px)
   Reorder EXACTLY as you requested:
   1) row: player-card + team-versus
   2) row: injury-report
   3) row: feature-menu
   4) row: last-15
   5) row: last-15-table + versus-team + shooting
   6) row: opponent-defense + position-defense
   7) row: premium-feature + props-table
   8) row: historical-logs
   9) row: similar-performance
*/
@media (max-width: 1100px) {
  .player-dashboard-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 900px;
    justify-content: center;
  }

  /* 1) first row side-by-side */
  .player-dashboard-player-card {
    order: 1;
    flex: 1 1 300px;
    max-width: 320px;
  }
  .player-dashboard-team-versus {
    order: 1;
    flex: 1 1 300px;
    max-width: 320px;
  }

  /* 2) second row */
  .player-dashboard-injury-report {
    order: 2;
    flex: 1 1 100%;
    max-width: 900px;
  }

  /* 3) third row */
  .player-dashboard-feature-menu {
    order: 3;
    flex: 1 1 100%;
    max-width: 900px;
  }

  /* 4) fourth row */
  .player-dashboard-last-15 {
    order: 4;
    flex: 1 1 100%;
    max-width: 900px;
  }

  /* 5) fifth row (3 items side by side) */
  .player-dashboard-last-15-table {
    order: 5;
    flex: 1 1 300px;
    max-width: 345px;
  }
  .player-dashboard-versus-team { /* or vs-team chart if you have a separate class */
    order: 5;
    flex: 1 1 300px;
    max-width: 376px;
  }
  .player-dashboard-shooting {
    order: 5;
    flex: 1 1 300px;
    max-width: 376px;
  }

  /* 6) sixth row */
  .player-dashboard-opponent-defense {
    order: 6;
    flex: 1 1 300px;
    max-width: 770px;
  }
  .player-dashboard-position-defense {
    order: 6;
    flex: 1 1 180px;
    max-width: 180px;
  }

  /* 7) seventh row */
  .player-dashboard-premium-feature {
    order: 7;
    flex: 1 1 300px;
    max-width: 569px;
  }
  .player-dashboard-props-table {
    order: 7;
    flex: 1 1 300px;
    max-width: 410px;
  }

  /* 8) eighth row */
  .player-dashboard-historical-logs {
    order: 8;
    flex: 1 1 100%;
    max-width: 100%;
  }

  /* 9) ninth row */
  .player-dashboard-similar-performance {
    order: 9;
    flex: 1 1 300px;
    max-width: 450px;
  }
}

/* ============== Existing Mobile/Tablet Adjustments (unchanged) ============== */
/* For screens narrower than 768px (mobile portrait) */
@media (max-width: 768px) {
  .player-dashboard-feature-menu {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .player-dashboard-feature-item {
    flex: 0 1 90px;
    margin: 5px;
    height: 40px;
    font-size: 14px;
  }
  .player-dashboard-feature-item .threshold-value {
    font-size: 12px;
  }

  .player-dashboard-chart-wrapper {
    height: auto;
  }
  .player-dashboard-chart-container {
    height: auto;
  }
}

/* For screens narrower than 576px */
@media (max-width: 576px) {
  .player-dashboard-player-selection {
    position: fixed;
    bottom: 10px;
    right: 200px;
    max-width: 220px; /* a visible width so the content shows up */
    height: auto;
    background-color: #fff; /* optional background if needed */
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    padding: 10px;
  }
  .player-dashboard-container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .player-dashboard-player-card,
  .player-dashboard-team-versus {
    max-width: 320px;
  }
  .player-dashboard-team-versus {
    margin-bottom: 25px;
  }
  .player-dashboard-injury-report {
    max-height: 400px;
  }
  .player-dashboard-feature-item {
    font-size: 16px;
    padding: 4px 2px;
  }
  .threshold-value {
    font-size: 14px;
  }
  .player-dashboard-chart-title {
    font-size: 20px;
  }
}

/* Very narrow screens (below 480px) */
@media (max-width: 480px) {
  .player-dashboard-over-count {
    top: 10px;
    right: 10px;
    font-size: 14px;
  }
  .player-dashboard-feature-item .threshold-value {
    font-size: 10px;
  }
}
