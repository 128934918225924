/* ChartCommon.css */
:root {
    --slider-width: 262.5px;
    --slider-margin: 8.75px;
    --switch-width: 45px;
    --switch-height: 24.5px;
    --switch-margin: 8.75px;
    --switch-border-radius: 24.5px;
    --switch-knob-size: 17.5px;
    --switch-knob-offset: 3.5px;
    --switch-knob-translate: 19.25px;
    --primary-color: #5470C6;
    --light-text-color: #ddd;
  }
  
  /* Slider and toggle switch styles */
  .slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 6px;
    width: 100%;
  }
  
  #constant-line-slider {
    width: var(--slider-width);
    margin: var(--slider-margin);
  }
  
  .slider-value {
    margin: 0 5px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .slider-value input {
    width: 50px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: var(--medium-text-color);
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: var(--switch-width);
    height: var(--switch-height);
    margin-left: var(--switch-margin);
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider.round {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--light-text-color);
    transition: 0.4s;
    border-radius: var(--switch-border-radius);
  }
  
  .slider.round:before {
    position: absolute;
    content: "";
    height: var(--switch-knob-size);
    width: var(--switch-knob-size);
    left: var(--switch-knob-offset);
    bottom: var(--switch-knob-offset);
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: var(--primary-color);
  }
  
  input:checked + .slider:before {
    transform: translateX(var(--switch-knob-translate));
  }
  