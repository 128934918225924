.ticket-watcher-container {
  width: 1000px;
  margin: 50px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
