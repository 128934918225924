.matchup-menu-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f0f3f8;
  position: relative;
  padding-left: 20px;
}

.matchup-menu-date-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  color: white;
  width: 80px;
  height: 35px;
  font-family: var(--font-main);
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.matchup-menu-container {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 10px;
  overflow: hidden;
}

.matchup-menu {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 10px; /* Adjusted for minimal spacing */
}

.matchup-menu-link {
  display: flex;
  flex: 1;
  cursor: pointer;
  min-width: 150px; /* Ensures flexibility */
  max-width: 190px;
  transition: background-color 0.2s ease-in-out;
}

.matchup-menu-link:hover {
  background-color: #d3d3d3; /* Light gray on hover */
}

.arrows-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.arrow-button {
  background-color: transparent;
  color: var(--main-color);
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 3px;
}

.arrow-button:disabled {
  color: #ccc;
  cursor: default;
}

.arrow-button.up::before {
  content: '\25B6'; /* Right pointing triangle */
}

.arrow-button.down::before {
  content: '\25C0'; /* Left pointing triangle */
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .matchup-menu-wrapper {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
  }

  .matchup-menu-date-box {
    width: 70px;
    height: 30px;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .matchup-menu-container {
    width: 100%;
    padding-left: 0;
    overflow-x: auto;
  }

  .matchup-menu {
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-start;
  }

  .matchup-menu-link {
    min-width: 100px;
    max-width: 100%;
    flex: 1 1 auto;
    margin-bottom: 5px;
  }

  .arrows-container {
    position: static;
    flex-direction: row;
    width: auto;
    margin-top: 10px;
  }

  .arrow-button {
    font-size: 18px;
    padding: 5px;
  }
}
