.footer {
  padding: 20px 40px;
  text-align: center;
  margin-top: 150px;
  font-family: var(--font-main);
}

.footer-logo {
  max-width: 250px;
  margin-bottom: 10px;
}
/* Footer.css */

.footer-discord {
  margin-top: 20px;
  text-align: center;
}

.discord-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: inherit; /* Inherit text color, or specify your own */
}

.discord-icon {
  width: 32px;    /* Adjust icon size as needed */
  height: 32px;
  margin-right: 8px; /* Spacing between the icon and the text */
}

.footer-description,
.footer-disclaimer,
.footer-helpline {
  color: #6d6d6d;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 15px;
}
.footer-contact {
  margin-top: 1rem;
  font-family: var(--font-roboto);
  color: var(--main-color);
}

.email-link {
  color: var(--detail-color);
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}

.helpline-highlight {
  font-weight: bold;
  color: #333;
}

.footer-links {
  margin-top: 10px;
}

.footer-link {
  color: #333;
  text-decoration: none;
  margin: 0 10px;
  font-weight: bold;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-socials {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icon {
  color: #6d6d6d;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #333;
}

.discord:hover {
  color: #5865f2;
}

.twitter:hover {
  color: #1da1f2;
}

.instagram:hover {
  color: #c32aa3;
}

.reddit:hover {
  color: #ff4500;
}
