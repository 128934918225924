.post-subscription-page {
    text-align: center;
    padding: 2rem;
    margin: 0 auto;
    max-width: 800px; /* optional, just to keep a nice layout */
  }
  
  /* Example font and color usage */
  /* You can place these in a global CSS if you prefer. */
  .font-main {
    font-family: "Montserrat";
  }
  .main-color {
    color: var(--main-color); /* Example "main" color (purple). Adjust to your brand color. */
  }
  
  /* Extra style for the call to action area */
  .call-to-action-container {
    margin-top: 3rem;
  }
  .call-to-action-container h2 {
    margin-bottom: 1rem;
  }
  
  /* Navigation links container */
  .navigation-links {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  /* Individual link styling */
  .nav-link {
    text-decoration: none;
    color: rgb(3, 210, 224); /* match your main color or accent color */
    font-weight: 600;
    transition: color 0.2s ease;
  }
  .nav-link:hover {
    color: rgb(3, 210, 224); /* slightly darker or lighter variant on hover */
    text-decoration: underline;
  }
  