/* === Card Container === */
.top-pick-card {
  width: 280px;
  min-height: 340px;
  border-top-right-radius: 25px;
  background-color: #f2f2f2;
  box-shadow: 8px 8px 16px -4px rgba(73, 0, 107, 0.36);
  padding: 7px 5px;
  font-family: var(--font-main);
  text-align: center;
  position: relative;
  /* Remove perspective from here; it is now on the flip-container */
  overflow: hidden;
  border: 1px solid transparent;
}

/* NEW: Flip container with perspective */
.flip-container {
  perspective: 1000px;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
}

/* === Overlay Icon Styles === */
.overlay {
  position: absolute;
  z-index: 1000;
  pointer-events: auto;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

/* Gold overlay positioned at top-left */
.gold-overlay {
  top: 147px;
  right: 4px;
}

.gold-overlay .overlay-icon {
  width: 30px;
  height: auto;
}

/* Accuracy overlay positioned at top-right */
.accuracy-overlay {
  top: 142px;
  right: 30px;
}

.accuracy-overlay .overlay-icon {
  font-size: 25px;
}

/* Fire overlay positioned at top-right */
.fire-overlay {
  top: 142px;
  right: 60px;
}

.fire-overlay .overlay-icon {
  font-size: 25px;
}

/* Tooltip for overlays */
.overlay .overlay-tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: -171%;
  transform: translateX(-50%);
  background: rgb(251, 251, 251);
  color: #6e12c6;
  font-weight: 550;
  padding: 10px 8px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 5px;
  z-index: 1100;
}

.overlay:hover .overlay-tooltip {
  display: block;
}

/* === Flip Animation Inner Container === */
.top-pick-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s ease;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
}

.top-pick-card-inner.flipped {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

/* === Card Faces === */
.top-pick-card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  top: 0;
  left: 0;
  padding: 13px 0;
  /* Force an explicit transform on the front */
  transform: rotateY(0deg);
}

.top-pick-card-face.back {
  transform: rotateY(180deg);
  left: 10px;
}

/* === FRONT FACE STYLES === */
.top-pick-card-title {
  color: var(--main-color, #5a2d82);
  font-weight: bold;
  text-align: left;
  padding-left: 22px;
  font-size: 24px;
  margin-bottom: 4px;
  margin-top: 10px;
}

.top-pick-card-feature {
  text-align: left;
  margin-left: 21px;
  font-size: 17px;
  margin-top: 15px;
  font-weight: 600;
  color: var(--main-color, #5a2d82);
}

.top-pick-card-values {
  display: flex;
  justify-content: space-around;
  padding: 0 25px 0 6px;
  margin-bottom: -13px;
  margin-top: -5px;
}

.top-pick-card-stat {
  font-size: 16px;
  text-align: left;
  color: #797979;
}

.top-pick-card-stat span {
  display: block;
  color: #2e2e2e;
  font-weight: bold;
}

.top-pick-card-divider {
  width: 80%;
  background-color: #e0e0e0;
  margin: 8px auto 0;
}

.top-pick-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-top: 6px;
  position: relative;
}

/* === L5 Pill === */
.top-pick-card-l5-pill {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 18px;
  margin-right: 125px;
  padding: 4px 10px;
  background-color: #fff;
  color: var(--main-color, #5a2d82);
  font-size: 17px;
}

.pill-value {
  font-weight: bold;
}

.top-pick-card-percentage.positive {
  color: #9ccd35;
}

.top-pick-card-percentage.negative {
  color: #cc3e2e;
}

/* === Chart Container === */
.top-pick-card-chart-container {
  width: 100%;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
}

.top-pick-card-chart-label {
  position: absolute;
  top: 5px;
  left: 92%;
  transform: translateX(-50%);
  color: var(--main-color, #5a2d82);
  font-weight: bold;
  font-size: 17px;
}

.top-pick-card-bars {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 151px;
  position: relative;
  gap: 4px;
  padding: 0 7px;
}

.top-pick-card-bar {
  flex: 1;
  background-color: #ccc;
  transition: height 0.3s ease;
}

.top-pick-card-bar.above-line {
  background-color: var(--main-color, #5a2d82);
}

/* === Dashed Line for the Line Value === */
.top-pick-card-line {
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: transparent;
  border-top: 2px dashed #6b6b6b;
  z-index: 1;
}

/* === Over/Under Indicators === */
.over {
  color: #9ccd35;
}

.under {
  color: rgb(247,64,55);
}

/* === BACK FACE STYLES === */
.top-pick-card-back-card-title {
  color: var(--main-color, #5a2d82);
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  margin-left: 33px;
  margin-top: -4px;
}

.top-pick-card-back-card-feature {
  margin: -19px 63px 16px 37px;
  font-size: 17px;
  text-align: left;
  font-weight: 600;
  color: var(--main-color, #5a2d82);
}

.top-pick-card-back-stats {
  text-align: left;
  line-height: 1.25;
  font-size: 14px;
  color: #333;
  margin-top: 10px;
  padding: 0 5px 0 35px;
}

.top-pick-card-injury-title {
  font-size: 14px;
  color: #5a5a5a;
  text-align: center;
  margin: 8px 0 4px;
  font-weight: bold;
}

.top-pick-card-injury-details {
  font-size: 12px;
  color: #666;
  margin-top: 6px;
  box-sizing: border-box;
}

.top-pick-card-injury-header,
.top-pick-card-injury-row {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin: 1px 0;
}

.top-pick-card-injury-header span,
.top-pick-card-injury-row span {
  flex: 1;
  text-align: center;
}

.top-pick-card-injury-header {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.top-pick-card-injury-row {
  padding: 2px 0;
}

/* === Flip Icon Style === */
.flip-icon {
  user-select: none;
}
