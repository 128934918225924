.team-versus-chart-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px; /* Ensures a baseline height */
}

/* The ECharts container */
.team-versus-team-chart {
  width: 100%;
  height: 100%;
}

/* Toggle container: flexible in top-right corner */
.team-vs-toggle-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 4px 8px;
  font-family: var(--font-main);
}

.toggle-label {
  font-size: 14px;
  margin-right: 4px;
}

/* Switch styling */
.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 50%;
}

/* On checked */
input:checked + .slider {
  background-color: var(--primary-color, #2196F3);
}

input:checked + .slider:before {
  transform: translateX(22px);
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

/* The “Back” text link in top-right corner if drilldown */
.team-vs-back-link {
  position: absolute;
  top: 20px;
  right: 22px;
  z-index: 10;
  font-size: 20px;
  color: #444;
  cursor: pointer;
  font-family: var(--font-main);
  /* text-decoration: underline; */
}
.team-vs-back-link:hover {
  color: #222;
}
