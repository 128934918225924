.similar-team-performance-container {
    width: 97%; /* Take full width of the parent container */

    overflow: hidden;
  }
  
  .similar-team-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

  }
  
  .similar-team-header-container h2 {
    font-size: 22px;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    color: var(--dark-text-color); /* Dark text color for header */
  }
  
  .similar-team-performance-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .similar-team-performance-table th,
  .similar-team-performance-table td {
    text-align: center; /* Center align headers and cells */
    font-family: 'Montserrat', sans-serif;
    color: var(--dark-text-color); /* Dark text color for table text */
  }
  
  .similar-team-performance-table tr {
    border-top: 1px solid var(--light-text-color); /* Medium text color for borders */

  }
  
  .similar-team-performance-table th {
    border-bottom: 1px solid var(--medium-text-color); /* Medium text color for borders */
    border-top: 1px solid white;
    font-size: 17px;
    color: var(--dark-text-color); /* Dark text color for headers */
    padding: 10px 8px; /* Adjusted padding for better spacing */
    font-weight: 650;
    
  }
  
  .similar-team-performance-table tbody tr:hover {
    background-color: var(--light-text-color); /* Row hover effect using light text color */
  }
  
  .team-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:6px 5px;
  }
  
  .similar-team-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .similar-team-performance-image {
    width: 80px;
    height: 80px;
    margin-bottom: 0px; /* Place name below the image */
    z-index: 50;
  }
  
  .similar-team-performance-name {
    font-size: 16px;
    font-weight: 545;
    margin-bottom:5px;
    text-align: center;
    color: var(--dark-text-color); /* Dark text color for team names */
  }
  
  .similar-team-chart-container {
    position: relative;
    z-index: 5; /* Higher z-index to ensure it's on top */
    top: 15px; /* Adjust as needed */
    left: 0; /* Adjust as needed */
    width: 100%; /* Adjust width as needed */
    height: 100%; /* Adjust height as needed */
  }
  
  @media screen and (max-width: 900px) {
    .similar-team-header-container h2 {
      font-size: 20px;
    }
  
    .similar-team-performance-image {
      width: 80px;
      height: 52px;
    }
  
    .similar-team-performance-name {
      font-size: 14px;
    }
  
    .similar-team-performance-number {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .similar-team-header-container h2 {
      font-size: 18px;
    }
  
    .similar-team-performance-image {
      width: 60px;
      height: 39px;
    }
  
    .similar-team-performance-name {
      font-size: 12px;
    }
  
    .similar-team-performance-number {
      font-size: 10px;
    }
  }
  
  @media screen and (max-width: 300px) {
    .similar-team-header-container h2 {
      font-size: 16px;
    }
  
    .similar-team-performance-image {
      width: 50px;
      height: 32.5px;
    }
  
    .similar-team-performance-name {
      font-size: 10px;
    }
  
    .similar-team-performance-number {
      font-size: 8px;
    }
  }
  