.team-comparison-bar-chart-container {
    padding: 1rem;
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    margin-top: 40px;
    height: 450px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  
  .team-comparison-bar-feature-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .team-comparison-bar-feature-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #3b82f6;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .team-comparison-bar-feature-button:hover {
    background-color: #2563eb;
  }
  
  .team-comparison-bar-feature-button.selected {
    background-color: #fbbf24;
    color: #1e3a8a;
  }
  
  .team-comparison-bar-feature-button.selected:hover {
    background-color: #f59e0b;
  }