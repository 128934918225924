.premium-feature-container {
  width: 92%;
  padding: 25px 22px;
  font-family: var(--font-roboto);
  position: relative;
}
.premium-feature-table-container {
  max-height: 300px;   /* Or 400px, etc. */
  overflow-y: auto;    /* Vertical scroll if content is tall */
  overflow-x: auto;    /* Horizontal scroll if table is wide */
  margin-top: 10px;    /* Some spacing above the table area */
}
.premium-feature-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.premium-feature-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 650;
  color: var(--dark-text-color);
  font-family: var(--font-main);
}
.selected-row {
  font-weight: bold;
}

.premium-feature-subtext {
  margin-left: 9px;
  margin-top: -26px;
  color: var(--medium-text-color);
  font-size: 14px;
}

.premium-feature-premium {
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  text-decoration: none;
  position: absolute;
  font-family: var(--font-main);
  top: 33px;
  right: 73px;
}

.premium-feature-table {
  width: 100%;
  border-collapse: collapse;
}

.premium-feature-table th,
.premium-feature-table td {
  text-align: left;
  padding: 10px 15px;
}

.premium-feature-table th {
  font-size: 14px;
  color: var(--dark-text-color);
  font-weight: 600;
  border-bottom: 1px solid var(--light-text-color);
  font-family: var(--font-main);
}

.premium-feature-table td {
  font-size: 14px;
  color: var(--medium-text-color);
  border-bottom: 1px solid var(--light-text-color);
}

.premium-feature-probability-bar {
  width: 100px;
  height: 10px;
  background-color: var(--light-text-color);
  border-radius: 5px;
  overflow: visible;
  position: relative;
}

.premium-feature-probability-bar-inner {
  height: 100%;
  border-radius: 5px;
}

.premium-feature-probability-tooltip {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 2px 6px;
  position: absolute;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  font-size: 12px;
  white-space: nowrap;
}

.premium-feature-probability-bar:hover .premium-feature-probability-tooltip {
  visibility: visible;
  opacity: 1;
}