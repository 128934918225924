@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  line-height: normal;
  font-family: var(--font-montserrat); /* Set the default font to Montserrat */
}

:root {
  /* fonts */
  --font-dm-sans: "DM Sans";
  --caption: "Open Sans";
  --main-color: rgb(107, 5, 202); /* Main color */
  --detail-color: #9ece17; /* Detail color */
  --font-roboto: "Roboto";
  --font-main: "Montserrat";
  --dark-text-color: #4F4F4F;
  --medium-text-color: #666666;
  --light-text-color: #d8d5d5a9;
  /* font sizes */
  --font-size-5xl: 24px;
  --font-size-sm: 14px;
  --font-size-6xl: 25px;
  --font-size-xl: 20px;
  --font-size-15xl: 34px;
  --caption-size: 12px;
  --font-size-lg: 18px;
  --font-size-mini: 15px;
  --font-size-lgi: 19px;
  --font-size-8xl: 27px;
  --font-size-base: 16px;

  /* Colors */
  --secondary-grey-300: #f4f7fe;
  --color-white: #fff;
  --color-gainsboro: #d9d9d9;
  --color-darkslateblue-100: #2b3674;
  --color-darkslateblue-200: #1b2559;
  --color-darkslateblue-300: #062e5c;
  --color-goldenrod-100: #ffc52f;
  --color-goldenrod-200: rgba(255, 197, 47, 0.8);
  --color-goldenrod-300: rgba(255, 197, 47, 0.7);
  --color-lightslategray: #8f9bba;
  --color-snow-100: #fffcfc;
  --color-snow-200: #fffbfb;
  --color-lightsteelblue: #a3aed0;
  --green: #7fc008;
  --color-mediumseagreen: #05cd99;
  --color-black: #000;
  --color-darkslategray: #333;
  --color-mediumslateblue: #0263ff;

  

  /* Gaps */
  --gap-3xs: 10px;
  --gap-xl: 20px;
  --gap-28xl-7: 47.7px;
  --gap-xl-7: 20.7px;
  --gap-mini: 15px;
  --gap-12xs-5: 0.5px;
  --gap-2xs-5: 10.5px;
  --gap-3xl: 22px;
  --gap-8xs: 5px;
  --gap-7xs-4: 5.4px;
  --gap-204xl-8: 223.8px;
  --gap-55xl-1: 74.1px;
  --gap-5xl: 24px;
  --gap-2xl: 21px;
  --gap-53xl: 72px;
  --gap-17xl: 36px;
  --gap-lg: 18px;
  --gap-11xs-5: 1.5px;
  --gap-6xs: 7px;
  --gap-9xs: 4px;

  /* Paddings */
  --padding-12xs: 1px;
  --padding-xs: 12px;
  --padding-xl: 20px;
  --padding-17xl: 36px;
  --padding-5xs-5: 7.5px;
  --padding-11xs: 2px;
  --padding-lgi: 19px;
  --padding-12xl: 31px;
  --padding-base: 16px;
  --padding-mini-3: 14.3px;
  --padding-7xl: 26px;
  --padding-62xl-7: 81.7px;
  --padding-mid: 17px;
  --padding-mid-3: 17.3px;
  --padding-3xl: 22px;
  --padding-lg: 18px;
  --padding-5xl-3: 24.3px;
  --padding-16xl: 35px;
  --padding-3xs: 10px;
  --padding-3xs-5: 9.5px;
  --padding-4xs: 9px;
  --padding-11xl-3: 30.3px;
  --padding-7xs: 6px;
  --padding-smi-5: 12.5px;
  --padding-11xl-6: 30.6px;
  --padding-6xs: 7px;
  --padding-9xs: 4px;
  --padding-sm: 14px;
  --padding-10xs: 3px;
  --padding-46xl-1: 65.1px;
  --padding-23xl: 42px;
  --padding-7xs-7: 5.7px;
  --padding-137xl: 156px;
  --padding-9xs-6: 3.6px;
  --padding-39xl-7: 58.7px;
  --padding-19xl: 38px;
  --padding-2xs-3: 10.3px;
  --padding-6xl: 25px;
  --padding-11xl: 30px;
  --padding-236xl: 255px;
  --padding-44xl: 63px;
  --padding-108xl: 127px;
  --padding-4xs-7: 8.7px;
  --padding-192xl: 211px;
  --padding-123xl: 142px;
  --padding-191xl-6: 210.6px;
  --padding-118xl: 137px;
  --padding-184xl-3: 203.3px;
  --padding-20xl: 39px;
  --padding-123xl-3: 142.3px;
  --padding-220xl: 239px;
  --padding-100xl: 119px;
  --padding-113xl: 132px;
  --padding-73xl: 92px;
  --padding-2xl: 21px;
  --padding-2xs: 11px;
  --padding-mini: 15px;
  --padding-10xl: 29px;
  --padding-9xl: 28px;
  --padding-smi: 13px;

  /* Border radiuses */
  --br-xl: 20px;
  --br-mini: 15px;
  --br-9xs: 4px;
  --br-3xs: 10px;
  --br-6xs: 7px;
  --br-81xl: 100px;
}
