.player-heatmap-container {
  padding: 20px;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
  max-width: 1440px;
  margin: 30px auto; /* Center and add top margin */
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.button-container button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-container button:hover {
  background-color: #0056b3;
}

.heatmap-table-wrapper {
  overflow-x: auto;
}

.player-heatmap-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto; /* Center the table */
}

.player-heatmap-table th, .player-heatmap-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.heatmap-cell {
  font-weight: bold;
  color: #fff;
  border-radius: 4px;
}

.heatmap-cell.above-line {
  background-color: #4caf50; /* Green */
}

.heatmap-cell.below-line {
  background-color: #f44336; /* Red */
}

.player-link {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}

.player-link:hover {
  color: #0056b3;
}
