/* Container for the cards grid */
.top-pick-cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    justify-items: center;
    box-sizing: border-box;
    background-color: #ffffff;
}

/* The filters row, centered */
.top-cards-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap; /* Let it wrap on smaller screens */
}
.add-to-cache-button {
    position: absolute;
    top: -17px;
    left: -16px;
    z-index: 1000;
    background-color: #f2f2f2;
    color: var(--main-color);
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-size: 35px;
    font-weight: 560;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 5px rgb(217 203 224);
}
.add-to-cache-button:hover {
    color: #f2f2f2;
    background-color: var(--main-color);
}
/* Each filter item => rename to top-cards-filter-item */
.top-cards-filter-item {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 110px;
    font-family: var(--font-main);
}

.top-cards-filter-item label {
    font-weight: 600;
    margin-bottom: 3px;
    color: var(--main-color); /* main color */
}

/* Multi-select styles with top-cards- prefix */
.top-cards-multi-select-wrapper {
    position: relative;
    cursor: pointer;
}
.top-cards-multi-select-control {
    position: relative;
    background: #f2f2f2;
    padding: 6px 35px 6px 10px;
    border-radius: 4px;
    user-select: none;
    min-width:50px;
    font-family: var(--font-main);
    color: var(--main-color);
}
.top-cards-multi-select-control:hover {
    background: #e4e4e4;
}
.top-cards-multi-select-control span {
    margin-right: 20px;
}
.top-cards-arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--main-color); /* arrow in main color */
    pointer-events: none;
}
.top-cards-multi-select-options {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px;
    margin-top: 2px;
    z-index: 1999;
    width: 150px;
}
.top-cards-option {
    margin-bottom: 4px;
}
.top-cards-option label {
    cursor: pointer;
    font-weight: normal;
    color: var(--main-color); /* match main color */
    font-family: var(--font-main);
}
.top-cards-option input[type="checkbox"] {
    margin-right: 6px;
    transform: scale(1.1);
}

/* Responsive columns */
@media (max-width: 1200px) and (min-width: 600px) {
    .top-pick-cards-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .top-pick-cards-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}
