.ticket-viewer-container {
    margin: 20px auto;
    max-width: 1440px; /* Set the container width to 1440px */
    padding: 0 10px; /* Add some horizontal padding for better spacing */
  }
  
  .date-picker-container {
    margin-bottom: 20px;
  }
  
  .apply-filter-btn {
    background-color: var(--main-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .apply-filter-btn:hover {
    background-color:var(--main-color);
  }
  
  .ticket-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 715px); /* Set two cards per row with 715px width each */
    gap: 10px; /* 10px gap between cards */
    justify-content: center; /* Center the cards within the container */
    margin-top: 20px;
  }
  
  .ticket-card {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    transition: transform 0.3s ease;
    max-width: 100%; /* Ensure the card doesn't exceed container width */
  }
  
  .ticket-card:hover {
    transform: translateY(-5px);
  }
  
  .ticket-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: var(--font-roboto);  /* Use Roboto for entries */
    max-width: 100%; /* Ensure the table does not exceed its container */
    table-layout: fixed; /* Makes the table layout more predictable */
  }
  
  .ticket-table th, .ticket-table td {
    padding: 8px;
    text-align: left;
    font-size: 12px;
    border: 1px solid var(--light-text-color);  /* Use light-text-color for row separators */
  }
  
  .ticket-table th {
    background-color: var(--dark-text-color);  /* Use dark-text-color for headers */
    color: white;
    font-family: var(--font-main);  /* Use main font for header */
  }
  
  .ticket-table td {
    color: var(--medium-text-color);  /* Use medium-text-color for entries */
  }
  
  .ticket-table tr:hover {
    background-color: #f0f0f0;  /* Light gray background when row is hovered */
  }
  
  .ticket-card h3 {
    margin-bottom: 12px;
    font-family: var(--font-main);  /* Use main font for header */
  }
  
  .ticket-card strong {
    font-weight: bold;
  }
  
  button {
    background-color: var(--main-color);;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color:whitesmoke;
  }
  