/* BlogPostsList.css */

/* Container for the entire list */
.blog-posts-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 1rem;
    font-family: 'Montserrat', sans-serif; /* In case you want Montserrat here too */
    color: var(--main-color, #333); /* Fallback if CSS var not found */
  }
  
  /* Each post item container */
  .blog-post-item {
    margin-bottom: 2rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
  }
  
  /* Title styling */
  .blog-post-title {
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0.5rem;
  }
  
  /* The content that displays Quill’s HTML */
  .blog-post-content {
    /* If you want to preserve multiple spaces & tabs, uncomment:
       white-space: pre-wrap; 
    */
    
    /* If you want text centered by default, uncomment:
       text-align: center; 
    */
  
    line-height: 1.6;
    margin: 0.5rem 0;
  }
  
  /* Meta info (author, created date) */
  .blog-post-meta {
    color: #666;
    font-size: 0.9rem;
  }
  
  .blog-post-meta strong {
    font-weight: bold;
  }
  
  /* Example color for code blocks if Quill code-block is used */
  .blog-post-content pre {
    background: #f5f5f5;
    padding: 1rem;
    overflow: auto;
    border-radius: 4px;
    font-family: 'Courier New', monospace;
  }
  