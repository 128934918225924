.matchup-players-player-card {
    display: flex;
    flex-direction: row;
    width: 90%;
    border: 1px solid #ddd;
    border-radius: 8px;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    transition: transform 0.5s ease; /* Slower and smoother transition */
  }
  .matchup-players-player-card:hover {
    transform: scale(1.08); /* Subtle enlargement */
}
  .matchup-players-player-info {
    flex: 1;
    min-height: 110px;
    max-height: 130px;
    max-width: 100px;
  }
  
  .matchup-players-player-predictions {
    flex: 2;
    min-height: 50px;
    max-width: 150px;
    max-height: 130px;
  }
  