

.terms-container {
font-family: var(--font-roboto);
line-height: 1.8;
color: #333;
padding: 20px;
max-width: 900px;
margin: auto;
}

.terms-container h1 {
font-family: var(--font-main);
color: var(--main-color);
text-align: center;
margin-bottom: 20px;
}

.terms-container h2 {
font-family: var(--font-main);
color: var(--main-color);
margin-top: 20px;
}

.terms-container p,
.terms-container ul {
margin-bottom: 15px;
}

.terms-container ul {
list-style: disc;
margin-left: 20px;
}

.effective-date {
font-style: italic;
color: var(--main-color);
}
