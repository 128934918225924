.team-comparison-chart {
  font-family: var(--font-main);
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
}

.team-comparison-chart-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.team-comparison-chart-button {
  background-color: #f0f0f0;
  border: 1px solid #d0d0d0;
  padding: 5px 15px;
  margin: 0 5px;
  border-radius: 15px;
  cursor: pointer;
  font-family: var(--font-main);
}

.team-comparison-chart-button.active {
  background-color: #0a2351;
  color: white;
}

.team-comparison-chart-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: bold;
}

.team-comparison-features-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Increased padding between bars */
}

.team-comparison-feature-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team-comparison-bar-value {
  font-size: 20px;
  font-weight: 600;
  min-width: 40px;
}

.team-comparison-bar-value.left {
  text-align: right;
  margin-top: 25px;
  padding-right: 10px;
}

.team-comparison-bar-value.right {
  text-align: left;
  margin-top: 25px;
  padding-left: 10px;
}

.team-comparison-bar-container {
  flex-grow: 1;
  margin: 0 10px;
  position: relative;
}

.team-comparison-bar-label {
  position: relative;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  font-weight: 600;
  color: var(--dark-text-color);
  font-family: var(--font-main);
  text-align: center;
}

.team-comparison-bar-label-underline {
  height: 1px;
  background-color: #b8b6b6;
  margin-top: 2px;
  width: 10%;
  transform: translateX(450%);
}

.team-comparison-bar {
  display: flex;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.team-comparison-bar > div {
  height: 100%;
  transition: width 0.3s ease;
}