.starting-lineup-container {
  width: 1440px;
  max-width: 1440px;
  margin: 0 auto;
}

.starting-lineup-content {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.starting-lineup-court {
  width: 1200px;
  height: 564px;
  background-image: url('../assets/aux_logos/basketball_court.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
  position: relative;
}

.starting-lineup-team {
  width: 48%;
  position: relative;
  height: 100%;
}

.starting-lineup-player-position {
  position: absolute;
  width: 100px;
  height: 150px;
  cursor: pointer; /* Indicates that it's clickable */
  transition: transform 0.3s ease; /* Smooth transition */
}

/* Hover effect for player card */
.starting-lineup-player-position:hover {
  transform: scale(1.05); /* Slight zoom */
  /* No changes to background or border */
}

/* Home team positioning */
.starting-lineup-home-team .starting-lineup-c { top: 23%; left: 0%; }
.starting-lineup-home-team .starting-lineup-pf { top: 65%; left: 8%; }
.starting-lineup-home-team .starting-lineup-sf { top: 12%; left: 38%; }
.starting-lineup-home-team .starting-lineup-sg { top: 72%; left: 40%; }
.starting-lineup-home-team .starting-lineup-pg { top: 43%; left: 52%; }

/* Away team positioning */
.starting-lineup-away-team .starting-lineup-c { top: -76%; right: -71%; }
.starting-lineup-away-team .starting-lineup-pf { top: -35%; right: -71%; }
.starting-lineup-away-team .starting-lineup-sf { top: -88%; right: -35%; }
.starting-lineup-away-team .starting-lineup-sg { top: -28%; right: -35%; }
.starting-lineup-away-team .starting-lineup-pg { top: -57.5%; right:-20%; }

.starting-lineup-team-logo {
  width: 175px;
  height: 175px;
  object-fit: contain;
  position: absolute;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.starting-lineup-team-logo:hover {
  transform: scale(1.1);
}

.starting-lineup-home-logo {
  left: -492px;
  bottom: -110px;
}

.starting-lineup-away-logo {
  right: -307px;
  top: -61px;
}


.starting-lineup-court-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.starting-lineup-title {
  text-align: left;
  font-size: 30px;
  margin-bottom: 20px;
  margin-left: 4px;
  margin-top: 5px;
}

.starting-lineup-tabs {
  display: flex;
  margin-bottom: 10px;
}

.starting-lineup-tab {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 12px;
}

.starting-lineup-tab.active {
  background-color: #fff;
  border-bottom: none;
}

.starting-lineup-tables {
  position: absolute;
  top: -74px;
  right: 50px;
  width: 300px;
  margin-left: -40px;
}

.starting-lineup-tables > * {
  margin-bottom: 20px;
}
