.position-versus-stats-container {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  font-family: var(--font-roboto);
  margin-bottom: 30px;



}

.position-versus-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
}

.position-versus-header-title {
  font-size: 1.2em;
  font-weight: bold;
  font-family: var(--font-main);
  color: var(--dark-text-color);
}

.info-button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 0.8em;
  color: var(--medium-text-color);
  border: 1px solid var(--medium-text-color);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.info-text {
  max-width: 300px;
  background-color: var(--dark-text-color);
  color: var(--light-text-color);
  text-align: center;
  border-radius: 6px;
  position: fixed;
  z-index: 1020;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: normal;
  padding: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

.info-text::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: var(--dark-text-color) transparent transparent transparent;
}

.info-button:hover .info-text {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.position-stats-table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
  margin-top: -10px;
  z-index: 1;
}

.position-stats-table th,
.position-stats-table td {
  padding: 12px 0px;

  color: var(--medium-text-color);
}

.position-stats-table th {
  background-color: #ffffff;
  color: var(--dark-text-color);
  margin-top:-20px;
  font-weight: 650;
  font-family: var(--font-main);
  border-bottom: 2px solid var(--light-text-color);
}

.position-stats-table th:first-child,
.position-stats-table td:first-child {
  text-align: left;
  padding-left: 15px; /* Move text in left column to the right */
}
.position-stats-table td:first-child {
  text-align: left;
  padding-left: 35px; /* Move text in left column to the right */
}
.position-stats-table th:last-child,
.position-stats-table td:last-child {
  text-align: right;
  padding-right: 15px; /* Increased padding to move text more to the left */
}

.position-stats-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.position-stats-table tbody tr:nth-child(odd) {
  background-color: #fff;
}

.position-stats-table tbody tr:hover {
  background-color: #e0e0e0;
}

.position-stats-table tbody td:first-child {
  font-weight: bold;
}

.position-stats-table tbody td {
  border-bottom: 1px solid var(--light-text-color);
}

.position-stats-table tbody tr:last-child td {
  border-bottom: none;
}
/* Highlight row for the selected player's position */
.highlighted-row {
  background-color: #f0f0f0; /* Slightly gray color for highlighting */
}

/* Legend styles */
.legend-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.legend-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.legend-hard .legend-color {
  background-color: rgb(247,64,55);
}

.legend-neutral .legend-color {
  background-color: black;
}

.legend-easy .legend-color {
  background-color: green;
}

/* Responsive design */
@media (max-width: 600px) {
  .position-versus-header-title {
    font-size: 1em;
  }

  .position-stats-table th,
  .position-stats-table td {
    padding: 8px 10px;
    font-size: 0.9em;
  }

  .position-stats-table th:first-child,
  .position-stats-table td:first-child {
    padding-left: 15px;
  }

  .position-stats-table th:last-child,
  .position-stats-table td:last-child {
    padding-right: 20px;
  }
}