.matchup-injury-table-container {
  display: flex;
  flex-direction: column;
  font-family: var(--font-roboto);
  overflow: hidden;
  border-radius: 8px;
  padding: 10px 0px;

  color: var(--dark-text-color);
}

.matchup-injury-table-title {
  flex-grow: 1;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: var(--dark-text-color);
  font-family: var(--font-main);
}

.matchup-injury-table-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  font-family: var(--font-main);
  color: var(--medium-text-color);
}

.matchup-injury-table-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-left: 10px;
}
.status-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.status-out {
  background-color: rgb(247,64,55);
}

.status-day-to-day {
  background-color: rgb(252, 215, 9);
}

.matchup-injury-table-selected-team-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  color: var(--medium-text-color);
  justify-content: space-between;
  padding-right: 20px;
}

.matchup-injury-table-selected-team {
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
}

.matchup-injury-table-dropdown-arrow {
  margin-left: auto;
}

.matchup-injury-table-autocomplete-container {
  position: absolute;
  top: 100%;
  right: 0;
  width: 120px;
  background: rgb(252, 252, 252);
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.matchup-injury-table-autocomplete-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.matchup-injury-table-autocomplete-item:hover {
  background-color: var(--team-color-hover, #e5e8e9); /* Fallback to a default color if --team-color-hover is not set */
}

.matchup-injury-table-content {
  flex-grow: 1;
  max-height: calc(3 * 50px + 16px); /* Approximate height for 3 rows plus header */
  overflow-y: auto;
}

.matchup-injury-table-table {
  width: 100%;
  border-collapse: collapse;
}

.matchup-injury-table-table th,
.matchup-injury-table-table td {
  padding: 8px;
  text-align: left;
  white-space: nowrap;
}

.matchup-injury-table-table td {
  font-size: 16px;
  color: var(--medium-text-color);
}

.matchup-injury-table-table thead th {
  color: var(--dark-text-color);
  font-weight: bold;
  background-color: inherit;
  padding: 10px;
  border-bottom: 1px solid var(--medium-text-color);
  font-size: 18px;
  font-family: var(--font-main);
}

.matchup-injury-table-table tbody td {
  font-family: var(--font-roboto);
}

.matchup-injury-table-status-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.matchup-injury-table-status-day-to-day {
  background-color: #FFC107; /* More vibrant yellow for Day-to-Day status */
}

.matchup-injury-table-status-out {
  background-color: rgb(247,64,55);
}

.matchup-injury-table-table tbody tr:nth-child(even) {
  background-color: rgba(151, 152, 153, 0.1);
}

.matchup-injury-table-table tbody tr:nth-child(odd) {
  background-color: rgb(255, 254, 254);
}

@media (max-width: 550px) {
  .matchup-injury-table-container {
    width: 100%;
  }
}
