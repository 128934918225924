.player-selection {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: all 0.3s ease;
  z-index: 1000;
}

.player-selection.minimized {
  width: 23px;
  height: 30px;
  max-height: none;
  overflow: hidden;
  padding: 5px;
}

.player-selection-toggle-button {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: inherit;
}

.player-selection-cart-item {
  margin: 5px 0;
  padding: 5px;
  color: inherit;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.911);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.player-selection-cart-item span {
  display: inline-block;
  flex-grow: 1;
  margin-right: 10px;
}

.remove-button {
  background-color: #ff4c4c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: #ff6666;
}

.player-selection button {
  background: #555;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
}

.player-selection button:hover {
  background: #777;
}

.player-selection select,
.player-selection input[type="text"] {
  margin: 5px;
  padding: 5px 10px;
}

.player-selection input[type="text"] {
  width: 70%;
  transition: width 0.3s ease;
}

.player-selection h2 {
  font-family: var(--font-main);
  color: inherit;
  font-size: 1.5em;
  font-weight: bold;
}

.player-selection h3 {
  font-family: var(--font-roboto);
  color: inherit;
  margin-top: 10px;
}
@media (max-width: 600px) {
.player-selection {
  position: fixed;
  right: 200px;

}
}