/* SelectedPlayers.css */
.selected-players-container {
  padding: 20px;
  width: 100%;
  max-height: 700px; /* Fixed height for scrollable area */
  font-family: var(--font-main);
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid var(--light-text-color); /* Optional: Add border */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
}

.selected-players-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-players-title {
  font-family: var(--font-main);
  color: var(--dark-text-color);
  margin-bottom: 15px;
  font-size: 24px;
  top: 0;
  background-color: white;
  z-index: 1; /* Ensures title remains visible */
}

.selected-players-filter {
  display: flex;
  align-items: center;
}

.selected-players-filter label {
  margin-right: 5px;
  font-weight: bold;
}

.selected-players-filter select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid var(--light-text-color);
}

.selected-players-table {
  width: 100%;
  border-collapse: collapse;
}

.selected-players-editable-input {
  width: 50px;
  font-size: 0.9em;
  padding: 2px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.selected-players-editable-input:focus,
.selected-players-pick-select:focus {
  border-color: var(--main-color);
  outline: none;
}

.selected-players-pick-select {
  font-size: 0.9em;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.selected-players-table th,
.selected-players-table td {
  font-family: var(--font-roboto);
  color: var(--medium-text-color);
  border-bottom: 1px solid var(--light-text-color);
  font-size: 16px;
  padding: 10px;
  text-align: left;
  vertical-align: middle; /* Align content of table cells to middle */
  position: relative; /* Necessary for absolute positioning of icons */
}

.selected-players-table th {
  cursor: pointer;
}

.selected-players-player-info {
  display: flex;
  align-items: center;
}

.selected-players-logo-container {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 10px;
}

.selected-players-team-logo-top-left {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  z-index: 1;
}

.selected-players-player-logo {
  width: 75px;
  margin-top:10px;
  height: 50px;
  z-index: 0;
}

.selected-players-details {
  display: flex;
  flex-direction: column;
}

.selected-players-name {
  font-weight: bold;
  margin-top:10px;
  margin-bottom: -15px;
  white-space: nowrap; /* Ensures player name stays on one line */
}

.selected-players-points {
  font-size: 0.9em;
  color: var(--medium-text-color);
}

.selected-players-bookie-logo {
  width: 40px;
  height: 40px;
}

/* Positioning the buttons separately within the same cell */
.selected-players-add-remove-buttons {
  position: relative;
  height: 100%;
}

.selected-players-add-btn, .selected-players-remove-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--main-color);
  font-size: 1.3em;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.selected-players-add-btn {
  left: 20%; /* Left-aligned inside the cell */
}

.selected-players-remove-btn {
  right: 40%; /* Right-aligned inside the cell */
}

.selected-players-add-btn:hover, .selected-players-remove-btn:hover {
  color: var(--main-color-hover);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Soft shadow for a more elevated look */
}

.modal-content h3 {
  margin-bottom: 20px;
  font-family: var(--font-main);
  color: var(--dark-text-color);
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.confirm-btn, .cancel-btn {
  background-color: var(--main-color);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-family: var(--font-roboto);
}

.confirm-btn:hover, .cancel-btn:hover {
  background-color: var(--main-color-hover);
}
