/* PredictionResultsTable.css */
.kpi-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .kpi-card {
    width: 165px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(113, 24, 255, 0.075);
    text-align: center;
    font-family: var(--font-main);
  }
  
  .kpi-card p {
    margin: 2px 0;
    font-size: 16px;
    font-family: var(--font-main);
    font-weight: bold;
    color: #333;
  }
  .projection-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .filter-group label {
    font-weight: bold;
    color: var(--main-color);
    margin-bottom: 5px;
    margin-left:5px;
    font-size: 16px;
  }
  
  .filter-group input,
  .filter-group select {
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  /* Accuracy value styling */
  .kpi-card-accuracy-value {
    font-size: 20px;
    color: var(--main-color);
  }
  
  /* Profit value styling */
  .kpi-card-profit-value {
    font-size: 19px;
    font-weight: bold;
  }
  
  .kpi-card-profit-value.positive {
    color: #05a000;
  }
  
  .kpi-card-profit-value.negative {
    color: rgb(129, 100, 100);
  }
  
  .hit-check {
    color: green;
    font-size: 18px;
  }
  
  .hit-miss {
    color: rgb(247,64,55);
    font-size: 18px;
  }
  