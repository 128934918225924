.team-stats-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    overflow: hidden;
  
    font-family: var(--font-roboto), sans-serif;
    background-color: var(--team-color);
    color: gray;
  }
  
  .team-table-header {
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .team-header-title {
    margin-top:5px;
    font-size: 19.25px;
    font-weight: 600;
    font-family: var(--font-main);
    color: var(--dark-text-color);
  }
  
  .team-header-icon::before {
    content: '';
    font-size: 19.25px;
    margin-left: 7px;
  }
  
  .team-stats-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .team-stats-table th,
  .team-stats-table td {
    padding: 8.55px 15.75px;
    text-align: left;
    white-space: nowrap;
  }
  
  .team-stats-table th {
    color: var(--dark-text-color)  ;
    font-weight: 550;
    background-color: inherit;
    padding: 5.25px 17.5px;
    border-bottom: 2px solid rgb(0, 0, 0);
    font-family: var(--font-main), sans-serif;
    font-size: 16.625px;
  }

  .team-stats-table td {
    color: var(--medium-text-color)  ;  ;
    font-size: 15px;
    padding: 7.25px 15.75px;
    font-weight: 450; /* Make the metric column slightly bolder */
    border-bottom: 1px solid rgb(236, 236, 236);
  }
  
  .team-even-row {
    background-color: white;
  }
  
  .team-odd-row {
    background-color: white;
  }
  
  .team-stats-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .team-stats-table {
    flex: 1 1 262.5px;
    max-width: calc(33.333% - 17.5px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    background-color: var(--team-color);
    color: white;
    transition: flex 0.3s ease;
  }
  
  @media (max-width: 900px) {
    .last-15-item {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  
  @media (max-width: 600px) {
    .last-15-item {
      flex: 1 1 262.5px;
      max-width: 100%;
    }
  }
  
  /* Arrow styles */
  .team-arrow-up {
    color: green;
    font-size: 15px;
    vertical-align: top;
  }
  
  .team-arrow-down {
    color: rgb(247,64,55);
    font-size: 15.4px;
    vertical-align: top;
  }
  
  /* Percentage difference styles */
  .team-percentage-diff {
    font-size: 12px;
    vertical-align: middle;
  }
  