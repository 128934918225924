.match-card {
  flex: 1;
  min-height: 360px; /* Keep your original desktop style */
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  font-family: var(--font-main);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-section {
  position: absolute;
  top: 10px;
  width: 100%;
  text-align: center;
  color: #FFD700;
  font-size: 15px;
  font-weight: bold;
  font-style: italic;
  font-family: var(--font-main);
  margin-top: 25px;
}

.middle-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo,
.logo2 {
  width: 90px;
  height: auto;
  margin: 0px 10px;
}

.team1-score,
.team2-score {
  font-size: 18px;
  font-family: var(--font-main);
  font-weight: bold;
  font-style: italic;
  margin: 10px 10px 50px 10px;
}

.bottom-section {
  position: absolute;
  bottom: 20px;
  font-family: var(--font-main);
  width: 100%;
  text-align: center;
  color: #003366;
  font-weight: bold;
  margin-bottom: 55px;
}

.bottom-section .time {
  font-size: 14px;
}

.bottom-section .venue {
  font-size: 14px;
}

.bottom-section .location {
  font-size: 14px;
  font-weight: bold;
}

/* 
   Responsive scaling on mobile.
   If your breakpoints differ, adjust (max-width: 600px).
   We'll force a square aspect ratio and slightly reduce dimensions.
*/
@media (max-width: 600px) {
  .match-card {
      /* min-height: auto; */
      /* width: 80vw; */
      max-width: 320px;
      aspect-ratio: 1 / 1;
      position: relative;
      padding: 0px;
  }

  .top-section {
    font-size: 13px;
    top: 25px;
    margin-top: 0; 
  }

  .logo, .logo2 {
    margin: 4px 5px 0px 8px;
    width: 100px;
  }
  .team1-score,
  .team2-score {
    font-size: 16px;
    margin: 8px 8px 30px 8px;
  }

  .bottom-section {
    bottom: 10px;
    margin-bottom: 50px;
  }
  .bottom-section .time,
  .bottom-section .venue,
  .bottom-section .location {
    font-size: 12px;
  }
}
