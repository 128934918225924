.versus-team-chart-container {
  width: 100%;

  border-radius: 12px;
  font-family: var(--font-main);
  overflow: hidden;
  height: 415px;
  position: relative;
  color: var(--dark-text-color);
}

.versus-team-chart {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-left:15px;
}

.versus-team-chart .echarts-for-react {
  font-size: 14px;
}


.versus-team-chart .echarts-for-react-legend,
.versus-team-chart .echarts-for-react-axis-label {
  color: var(--medium-text-color);
}

.versus-team-chart .echarts-for-react-axis-line {
  color: var(--light-text-color);
}

.versus-team-chart .echarts-for-react-grid {
  bottom: 100px;
}

.versus-team-chart .echarts-for-react-tooltip {
  background-color: var(--medium-text-color);
  color: var(--dark-text-color);
}

.versus-team-chart .echarts-for-react-tooltip-arrow {
  color: var(--medium-text-color);
}

.versus-team-chart .echarts-for-react-yaxis,
.versus-team-chart .echarts-for-react-line,
.versus-team-chart .echarts-for-react-label,
.versus-team-chart .echarts-for-react-axis-name {
  color: var(--dark-text-color);
}

.versus-team-chart .echarts-for-react-bar {
  color: var(--medium-text-color);
}

.versus-team-chart .echarts-for-react-split-line {
  color: var(--light-text-color);
}