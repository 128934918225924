
.projected-stats-table {
  background-color: #ffffff;
  border-radius: 15px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
  padding:12px;
  font-family: var(--font-roboto);
}

.projected-stats-table table {
  width: 100%;
  border-collapse: collapse;
}

.projected-stats-table th, .projected-stats-table td {
  padding: 8px 10px;
  text-align: center;
}

.projected-stats-table .table-title {
  background-color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
  font-family: var(--font-main);
  color: var(--dark-text-color);
}

.projected-stats-table thead tr:last-child th {
  font-family: var(--font-main);
  color: var(--dark-text-color);
}

.projected-stats-team-logo {
  width: 40px;
  height: 40px;
  vertical-align: middle;
  margin-right: 4px;
}

.projected-stats-table .team-name {
  font-size: 12px;
  font-weight: bold;
}

.projected-stats-table tbody td:first-child {
  text-align: left;
  padding-left:20px;
  font-weight: 550;
  font-family: var(--font-roboto);
  color: var(--medium-text-color);

}

.projected-stats-table tbody td {
  color: var(--medium-text-color);
  border-bottom: 1px solid var(--light-text-color);
 
}

.projected-stats-table tbody tr:last-child td {
  border-bottom: none;
}