/***********************************************************
  GLOBAL CONTAINER
************************************************************/
.landing-page-container {
  width: 100%;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
  background-color: #f2f2f2;
}

/***********************************************************
  HERO SECTION
************************************************************/
.hero-section {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  margin: 0;
}

.hero-bg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.hero-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  min-width: 1920px; /* if your SVG is 1920x1080 */
}

.hero-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left; /* or center if you prefer */
  color: #ffffff;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  padding: 0 20px;
}

.hero-title {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1.2rem;
  color: white;
  line-height: 1.2;
}

.hero-subtitle {
  font-size: 1.3rem;
  font-weight: 400;
  margin-left:-280px;
  text-align:left;
  margin-bottom: 2rem;
  color: white;
  line-height: 1.4;
}

.highlight {
  font-weight: 700;
  color: #ffffff;
}

.hero-button {
  background-color:  #C8ED2E;
  color: #8200FF; 
  font-size: 1rem;
  font-weight: 700;
  margin-right: -510px;
  padding: 12px 28px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.hero-button:hover {
  background-color: whitesmoke;
}

/***********************************************************
  WHAT WE OFFER SECTION
************************************************************/
.what-we-offer-section {
  background-color: #f2f2f2; /* Light grey */
  color: #8200FF;       /* Purple text */
  position: relative;
  padding: 60px 20px 40px 20px;
}

.offer-header {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}

.offer-header h2 {
  font-size: 36px;
  font-weight: 800;
  margin: 0;
  color: #8200FF;
}

.circle-decor {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.offer-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}

.offer-item {
  text-align: left;
  max-width: 520px;
  margin: 0 auto;
}

.offer-item h3 {
  color: #8200FF;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  display: inline-block;
  border-radius: 4px;
}

.offer-item p {
  text-align: left;
  color: #1d1d1d;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
}

/***********************************************************
  MACHINE LEARNING SECTION
************************************************************/
.machine-learning-section {
  position: relative;
  width: 100%;
  background-color: #8200ff; /* main purple */
  color: #f9f9f9;
  padding: 60px 0px;
  overflow: hidden;
  border-top-right-radius: 400px; /* big curve top-right */
}

.ml-background-svg {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  z-index: 1; 
  pointer-events: none;
}

.ml-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2; 
  text-align: left; 
}

.ml-title {
  font-size: 36px;
  font-weight: 800;
  margin-top: -20px;
  margin-bottom: 14px;
  text-align: center;
  color: #fff;
}

.ml-content {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  align-items: center;
  justify-content: space-between;
}

.ml-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.ml-screenshot {
  width: 100%;
  max-width: 350px;
  margin-top: 30px;
  display: block;
}

.ml-text {
  flex: 1;
  display: flex;
  margin-top:-30px;
  flex-direction: column;
  gap: 1.2rem;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #fff;
}
.ml-text h {
  font-weight: 550;
  font-size: 30px;
  color: #fff;
}

/***********************************************************
  PRICING SECTION CONTAINER
************************************************************/
.pricing-section {
  padding: 40px 20px;
  text-align: center;
  background-color: #f2f2f2; /* or whatever your page background is */
}

.pricing-title {
  margin-top:-10px;
  font-size: 36px;
  font-weight: 800;
  color: #8200FF;
  margin-bottom: -5px;
}




/***********************************************************
  PRICING CARDS WRAPPER
************************************************************/
.pricing-cards-wrapper {
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
}

/***********************************************************
  BASE CARD STYLE
************************************************************/
.pricing-card {
  position: relative;  /* needed for absolutely-positioned button */
  width: 280px;
  height: 350px;       /* slightly taller to fit the larger text & button */
  border-top-right-radius: 20px; /* Light corner rounding across top */
  box-shadow: 8px 8px 16px -4px rgba(95, 1, 139, 0.363);

  padding: 24px 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: visible; /* so the ribbon can appear above the card */
}

/***********************************************************
  "NO CARD REQUIRED!" RIBBON (FREE CARD)
************************************************************/
.ribbon-label {
  position: absolute;
  top: -14px;        /* move up a bit more */
  left: 16px;
  z-index: 9999;
  background-color: #8200ff;
  color: #ffffff;
  font-size: 0.9rem; /* larger text */
  font-weight: 700;
  padding: 6px 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

/***********************************************************
  FREE CARD vs PREMIUM CARD
************************************************************/
/* FREE card => gray background & text in var(--main-color) */
.free-card {
  background-color: #f5f5f5;
  color: #8200FF;
}

/* PREMIUM card => main-color background, white text */
.premium-card {
  background-color: #8200FF;
  color: #f5f5f5;
}

/***********************************************************
  TEXT INSIDE THE CARD
************************************************************/
.card-tier {
  font-size: 1.58rem;  /* larger */
  font-weight: 700;
  margin-bottom: 10px;
}

/* If the card has dark BG (premium), text is white; 
   if it’s free-card, text is main-color */
.card-price {
  font-size: 2.7rem; /* bigger price */
  font-weight: 900;
  margin-bottom: 6px;
}

.billed-monthly {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 16px;
}

.card-features {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 18px;  /* bigger text */
}

.card-features li {
  margin-bottom: 8px;
}

/***********************************************************
  BOTTOM BUTTON
************************************************************/
.card-button {
  position: absolute;   /* pinned inside the card */
  bottom: 20px;
  right: 20px;
  border: none;
  font-weight: 700;
  font-size: 0.95rem;
  padding: 10px 16px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(130, 0, 255, 0.2);
  transition: background-color 0.3s ease;
}

/* FREE card button => keep purple (#8200ff) or brand color */
.free-card .card-button {
  background-color: #8200ff;
  color: #fff;
}

/* PREMIUM card button => green with purple text */
.premium-card .card-button {
  background-color:  #C8ED2E;
  color: #8200FF;  
}

/* Hover states */
.free-card .card-button:hover {
  background-color: #8200FF;
}

.premium-card .card-button:hover {
  background-color:  #C8ED2E;
}

/***********************************************************
  FAQ SECTION
************************************************************/
.faq-section {
    position: relative;
    width: 100%;
    background-color: #8200ff;
    color: #f9f9f9;
    padding: 60px 0px;
    overflow: hidden;
    min-height: 500px;
    border-top-left-radius: 400px;
}

/* The left-side curved SVG background */
.faq-bg-left {
  position: absolute;
  top: 0;
  left: 0;
  /* or use width: auto; if your curve is wide.
     You can also do "object-fit: cover" if desired (for images).
  */
  z-index: 0;
  pointer-events: none;
}

/* The container that sits above the background */
.faq-container {
  position: relative;
  z-index: 1; /* above the background */
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  text-align: center; 
}

.faq-title {
  margin-top: -10px;
  color: #f2f2f2; 
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 20px;
}

/* Circles below the title */
.circle-decor {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.circle-decor img {
  width: 150px; /* adjust as needed */
  height: auto;
}

/* The FAQ items container */
.faq-content {
  margin-bottom: -100px;
  margin-top:100px;
  color:#f2f2f2;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
}

/* Individual FAQ item */
.faq-item {
  border-top: 1px solid #ccc;
  padding: 10px 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
}

.faq-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #f2f2f2;
  font-size: 24px;
  transition: transform 0.3s ease;
}

.faq-answer {
  display: none;
  padding: 10px;
  font-size: 16px;
  color: #f2f2f2;
}


/***********************************************************
  RESPONSIVE BREAKPOINTS
************************************************************/
@media (max-width: 768px) {
  /* Hero: smaller text, etc. */
  .hero-title {
    font-size: 2rem;
  }
  .hero-subtitle {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    margin-left:5px;
  }
  .hero-button{
    margin-right: 0px;
  }
  .hero-section{
    height:65vh;
  }
  .hero-content {
    height: 96%;
   
  }
  /* Offer grid => single column */
  .offer-grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .machine-learning-section {
    border-top-right-radius: 100px; /* smaller curve if you want */
  }
  .faq-section{
    border-top-left-radius: 100px; /* smaller curve if you want */
  }
  .ml-content {
    flex-direction: column;
    text-align: center;
  }
  .ml-text {
    margin-top: 20px;
  }

  /* Pricing => stack cards */
  .pricing-cards-wrapper {
    flex-direction: column;
  }
  .pricing-card {
    width: 90%;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  /* Even smaller adjustments */
  .hero-title {
    font-size: 1.65rem;
  }
  .hero-subtitle {
    font-size: 1.05rem;
    margin-left:-50px;
  }
  .hero-button{
    margin-left:170px;
    font-size: 0.85rem;
    width: 42%;
  }
  .card-price {
    font-size: 2.4rem;
  }
}
