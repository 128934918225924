.player-share-chart-wrapper {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 12px;
  font-family: var(--font-main), sans-serif;
  overflow: hidden;
  height: 337.5px;
  position: relative;
  padding-top: 8.75px;
}

.player-share-chart-title {
  text-align: center;
  font-size: 21px;
  font-weight: bold;
}

.player-share-chart-container {
  width: 90%;
  height: 100%;
}

.player-share-toggle-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: absolute;
  top: 15px;
  left: 10px;
}

.player-share-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 27px;
  margin-right: 10px;
}

.player-share-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.player-share-slider.player-share-round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 28px;
}

.player-share-slider.player-share-round:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.player-share-switch input:checked + .player-share-slider {
  background-color: var(--primary-color);
}

.player-share-switch input:checked + .player-share-slider:before {
  transform: translateX(22px);
}

.legend-highlight {
  font-weight: bold;
  text-shadow: 12px 11px 22px #000;
}
