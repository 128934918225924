.spread-bar-wrapper {
  display: flex;
  justify-content: center;
}

.spread-bar {
  position: relative;
  width: 400px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
}

.spread-away, .spread-home {
  height: 100%;
}

.spread-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-family: var(--font-main);
  text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
}