.player-logs-historical-container {
  font-family: var(--font-roboto); /* Set Roboto as default for the table content */
  border-radius: 10.29px;
  padding: 0px 10.29px;
  background-color: #ffffff;
  max-width: 980px; /* Desktop pinned width */
  margin: 20.58px 0;
  position: relative;
  transition: width 0.3s ease, max-width 0.3s ease, height 0.3s ease;
  margin-left: 25px; /* Desktop margin left */
}

.player-logs-historical-container.full-screen {
  width: 1540px;
  max-width: 100%;
  height: 100%;
  z-index: 1000;
  border: 2px solid var(--light-text-color);
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  overflow: auto;
}

.popout-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: var(--medium-text-color);
  z-index: 1100;
}

/* 
   The .player-logs-table-container now includes the filters.
   It's the single "visual" container for logs + filters.
*/
.player-logs-table-container {
  max-height: 687.14px;
  overflow-y: auto;
  position: relative;
}

/* Filters container is flexible, wrapped inside .player-logs-table-container */
.filters-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin: 10px 0; 
  flex-wrap: nowrap; /* Desktop: single row */
}

.filters-container label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--medium-text-color);
}

.feature-dropdown-container {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.feature-dropdown-container label {
  margin-right: 8px;
  font-size: 14px;
  color: var(--medium-text-color);
}

#feature-select,
.filter-select,
.filter-input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--light-text-color);
  font-size: 14px;
  color: var(--dark-text-color);
  margin-top: 5px;
}

.minutes-filter-container {
  display: flex;
  gap: 5px;
}

/* Title, Table, etc. */
.player-logs-historical-title {
  margin-bottom: 10.29px;
  font-size: 24.86px;
  font-weight: bold;
  font-family: var(--font-main);
  color: var(--dark-text-color);
  text-align: left;
}

.player-logs-historical-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.player-logs-historical-header,
.player-logs-historical-cell {
  border: none;
  padding: 12.34px 8.23px;
  text-align: left;
  white-space: nowrap;
}

.player-logs-historical-header {
  background-color: #ffffff;
  font-family: var(--font-main);
  color: var(--dark-text-color);
  font-weight: bold;
  border-bottom: 2.06px solid var(--light-text-color);
}

.player-logs-historical-cell {
  font-family: var(--font-roboto);
  color: var(--medium-text-color);
  font-size: 15.46px;
  border-bottom: 1.03px solid var(--light-text-color);
}

.player-logs-historical-row:hover {
  background-color: #f9f9f9;
}

.player-logs-historical-cell img.opponent-logo {
  width: 30.86px;
  height: 30.86px;
  border-radius: 50%;
  margin-right: 10.29px;
  vertical-align: middle;
}

/* Remove max-height limit in full-screen mode */
.player-logs-historical-container.full-screen .player-logs-table-container {
  max-height: none;
}

/* ========== Responsive Styles ========== */
@media screen and (max-width: 900px) {
  .player-logs-historical-container {
    max-width: 720px !important;
    margin-left: 10px;
    margin-right: 10px;
  }
  .player-logs-historical-title {
    font-size: 20px;
  }
  .player-logs-table-container {
    max-height: 500px;
  }
  .player-logs-historical-header,
  .player-logs-historical-cell {
    padding: 10px 6px;
  }
  .player-logs-historical-cell {
    font-size: 14px;
  }
  .player-logs-historical-cell img.opponent-logo {
    width: 25px;
    height: 25px;
    margin-right: 8px;
  }
}

@media screen and (max-width: 600px) {
  .player-logs-historical-container {
    max-width: 480px !important;
    padding: 6px;
    margin: 12px 0;
    height: auto !important;
    margin-left: 5px;
    margin-right: 5px;
  }
  .player-logs-historical-title {
    font-size: 16px;
    margin-bottom: 6px;
  }
  .player-logs-table-container {
    max-height: 370px;
  }

  /* 2-row layout for filters on smaller screens */
  .filters-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 10px;
  }

  .player-logs-historical-header,
  .player-logs-historical-cell {
    padding: 7px 5px;
  }
  .player-logs-historical-cell {
    font-size: 10px;
  }
  .player-logs-historical-cell img.opponent-logo {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 300px) {
  .player-logs-historical-container {
    max-width: 240px !important;
    padding: 4px;
    margin: 8px 0;
  }
  .player-logs-historical-title {
    font-size: 12px;
    margin-bottom: 4px;
  }
  .player-logs-table-container {
    max-height: 220px;
  }
  .player-logs-historical-header,
  .player-logs-historical-cell {
    padding: 4px 3px;
  }
  .player-logs-historical-cell {
    font-size: 6px;
  }
  .player-logs-historical-cell img.opponent-logo {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
}
