/* -------------------------
   Global Page Container
------------------------- */
.subscription-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f9fa; /* Light background */
  font-family: var(--font-main);
}

/* -------------------------
   Main Container
------------------------- */
.subscription-page-container {
  text-align: center;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}

.subscription-page-container h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333333;
}

.subscription-page-container p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555555;
}

/* Early Dev Text */
.subscription-page-early-dev-text {
  font-size: 16px;
  line-height: 1.4;
  color: #666666;
  margin-bottom: 20px;
}

.subscription-page-early-dev-text2 {
  font-size: 16px;
  line-height: 1.4;
  color: #666666;
  margin-top: 20px;
}
/* PayPal Button Container */
.subscription-page-paypal-button-container {
  margin-top: 20px;
}

/* Messages */
.subscription-page-success-message {
  color: #28a745;
  font-weight: bold;
  margin-bottom: 10px;
}

.subscription-page-warning-message {
  color: #d39e00;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Action Buttons */
.subscription-page-action-button {
  background-color: #007bff;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 6px; /* slight spacing between them */
}

.subscription-page-action-button:hover {
  background-color: #0056b3;
}

/* -------------------------
   Pricing Section
------------------------- */
.subscription-page-pricing-section {
  /* Apply the purple background and padding like your snippet */
  text-align: center;
}

/* Container for multiple cards (flex-wrap, gap, center) */
.subscription-page-pricing-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

/* Single pricing card, large border, box shadow, etc. */
.subscription-page-pricing-card {
  background-color: #ffffff;
  position: relative;
  width: 300px;
  min-height: 480px;
  border: 5px solid #f1c50e; /* The "default" border color from snippet */
  border-radius: 26px;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column; /* so footer can be pinned at bottom */
  margin-top: 40px;       /* in case you're overlapping a logo, etc. */
}

/* Overlapping logo container (if you want to keep your image floating above) */
.subscription-page-card-logo-container {
  position: absolute;
  top: -50px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.subscription-page-card-logo {
  width: 88px;
  height: auto;
  z-index: 2;
  margin-top: 44px;
  margin-left: -215px;
}
.nav-link {
  display: inline-block;
  /* margin-top: 0.5rem; */
  margin-left: 3px;
  word-spacing: normal;
  color: #7a3cff;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.2s ease;
}
/* Header bar inside the card (like the snippet's .pricing-card-header) */
.subscription-page-pricing-card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  height: 60px;
  border-radius: 26px 26px 0 0;
  background-color: #f1c50e; /* or #4b0080, depending on your tier color */
  position: relative; 
  /* If you want extra top padding because of the overlapping logo, add it:
     padding-top: 60px;
     padding-bottom: 15px;
  */
}

/* Tier label (like .card-tier in snippet) */
.subscription-page-card-tier {
  font-size: 20px;
  font-weight: 800;
  margin-left: 156px;
  color: #000; /* black text on the yellow bar */
}

/* Card Body */
.subscription-page-pricing-card-body {
  flex: 1;
  padding: 20px;
  text-align: center;
}

/* Big Price text */
.subscription-page-pricing-card-price {
  font-size: 70px;
  font-weight: 900;
  color: #4b0080;
  margin: 9px 155px 28px 3px; /* adjust as needed to mimic snippet spacing */
}

/* Feature bullet list */
.subscription-page-pricing-features-list {
  list-style: disc;
  margin: 0;
  padding: 0 0 0 20px;
  text-align: left;
}
.subscription-page-pricing-features-list li {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

/* Footer pinned at bottom of card */
.subscription-page-pricing-card-footer {
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 8px; /* spacing between buttons */
  border-radius: 0 0 26px 26px; /* match the top rounding of 26px */
  background-color: #ffffff;    /* or #f1f3f5 if you prefer a gray strip */
}

/* Buttons in the card footer */
.subscription-page-pricing-card-button {
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  background-color: #f1c535;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  transition: background-color 0.3s ease;
  width: 80%;
  max-width: 250px;
  height: 40px;
  white-space: nowrap;
}

.subscription-page-pricing-card-button:hover {
  background-color: #ffdd48;
}

/* Purple "Subscribe" button specifically */
.subscription-page-subscribe-button {
  background-color: #f1c535 ;

}
