.schedule-container {
  width: 100%;
  max-width: 900px;
  background-color: #fff;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.schedule-team-toggle {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.schedule-team-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  align-items: center;
  font-weight: 600;
  padding: 10px;
  font-family: var(--font-main);
  color: var(--dark-text-color);
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.schedule-team-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.schedule-team-button.active {
  border-bottom: 2px solid #000;
}

.schedule-team-logo-button {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

/* Keep all your existing CSS */

/* Add these new styles for the scrollable table */
.schedule-table-wrapper {
  max-height: 690px; /* Adjust this value to fit exactly 15 rows plus the header */
  overflow-y: auto;
  margin-bottom: 20px;
}

.schedule-table thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

/* Ensure the table takes up the full width of its container */
.schedule-table {
  width: 100%;
}

.schedule-table th,
.schedule-table td {
  padding: 8px;
  font-family: var(--font-main);
  text-align: center;
  border-bottom: 1px solid var(--light-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.schedule-table th {
  font-family: var(--font-main);
  font-weight: bold;
  font-size: 18px;
  color: var(--dark-text-color);
}

.schedule-table td {
  font-family: var(--font-roboto);
  font-weight: normal;
  font-size: 16px;
  color: var(--medium-text-color);
}

.schedule-team-logo-mini {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}

.schedule-win {
  color: #28a745;
}

.schedule-loss {
  color: #dc3545;
}

.schedule-green {
  color: #28a745;
}

.schedule-red {
  color: #dc3545;
}

.schedule-home-score-green {
  color: #28a745; /* Bright green for positive home team score */
  font-weight: bold;
}

.schedule-home-score-red {
  color: #dc3545; /* Bright red for negative home team score */
  font-weight: bold;
}

.schedule-away-score-green {
  color: #28a745; /* Bright green for positive away team score */
  font-weight: bold;
}

.schedule-away-score-red {
  color: #dc3545; /* Bright red for negative away team score */
  font-weight: bold;
}

/* Additional existing classes */

.schedule-win {
  color: #28a745; /* Winning score */
}

.schedule-loss {
  color: #dc3545; /* Losing score */
}

.schedule-green {
  color: #28a745; /* Positive points highlight */
}

.schedule-red {
  color: #dc3545; /* Negative points highlight */
}
