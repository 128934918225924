:root {
  --font-main: 'Roboto', sans-serif;
  --primary-bg: #f7f7f7;
  --secondary-bg: #ffffff;
  --primary-color: #003366;
  --accent-color: #007acc;
  --text-color: #333333;
  --border-color: #cccccc;
}

.boxscore-container {
  padding: 2rem;
  min-height: 100vh;
  background-color: var(--primary-bg);
  font-family: var(--font-main);
  color: var(--text-color);
  text-align: center;
}

.boxscore-container h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.boxscore-filters {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.boxscore-select {
  background-color: var(--secondary-bg);
  border: 1px solid var(--border-color);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: var(--text-color);
  border-radius: 4px;
  margin: 0 0.5rem;
  outline: none;
}

.boxscore-select:focus {
  border-color: var(--accent-color);
}

.boxscore-content {
  background-color: var(--secondary-bg);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 0 auto 2rem auto;
  max-width: 1200px;
}

.boxscore-content h2 {
  font-size: 2rem;
  margin-bottom: 0.75rem;
  color: var(--primary-color);
  border-bottom: 2px solid var(--accent-color);
  padding-bottom: 0.5rem;
}

.game-info {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.game-info p {
  margin: 0.5rem 0;
}

/* Per Quarter Scores Table */
.quarters-table-container {
  margin-bottom: 2rem;
}

.quarters-table-container h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

.quarters-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.quarters-table th,
.quarters-table td {
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  text-align: center;
  font-size: 0.95rem;
}

/* Teams Box Score Layout */
.teams-boxscore {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.team-boxscore {
  flex: 1 1 48%;
  background-color: var(--secondary-bg);
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  margin-bottom: 1rem;
}

/* Team Summary Header */
.team-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color);
  padding: 0 1rem;
}

.team-summary .team-pts,
.team-summary .team-minutes {
  /* You can adjust spacing here */
}

/* Table container: centered, max-width 1200px, horizontal scrolling if needed */
.players-table-container {
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: auto;
}

.players-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.players-table th,
.players-table td {
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  text-align: center;
  font-size: 0.9rem;
}

.players-table th {
  background-color: var(--primary-color);
  color: #ffffff;
}

.totals-row {
  font-weight: bold;
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .team-boxscore {
    flex: 1 1 100%;
  }
}
