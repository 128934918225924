.matchup-players-player-info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: var(--font-roboto); /* Font for player info content */
}

.matchup-players-color-split {
    height: 45%;
    display: flex;
    position: relative; /* Set relative position for the color boxes */
}

.matchup-players-color-box {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-main); /* Font for color box text */
    position: relative; /* Relative for text positioning */
}

/* Position Text */
.matchup-players-position {
    font-size: 14px; /* Adjust size of position text */
    font-weight: bold;
    position: absolute; /* Absolute positioning */
    top: 15px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    z-index: 1; /* Ensures it's above the background */
}

/* Number Text */
.matchup-players-number {
    font-size: 11px; /* Adjust size of number */
    font-weight: bold;
    position: absolute; /* Absolute positioning */
    top: 20px; /* Adjust as needed */
    right: 12px; /* Adjust as needed */
    z-index: 1; /* Ensures it's above the background */
}

.matchup-players-player-image {
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -43%);
    height: 86%;
    object-fit: cover;
}

.matchup-players-player-name {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 550;
    font-family: var(--font-main); /* Font for player name */
    color: white;
}

.matchup-players-player-stats {
    height: 40%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3px;
    padding: 4px;
}

.matchup-players-stat-label {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    text-align: left;
    padding-left: 12px;
    font-family: var(--font-roboto); /* Font for stat labels */
}

.matchup-players-stat-value {
    font-size: 12px;
    color: white;
    text-align: right;
    font-weight: 550;
    padding-right: 12px;
    font-family: var(--font-roboto); /* Font for stat values */
}
