.matchup-header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.matchup-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #fff;
  width: 100%;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for the card look */
}

/* Left Side (Away Team) with Border Container */
.matchup-header-left-border {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%; /* 5% wider for the outer border */
  position: relative;
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
}

.matchup-header-left-side {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  padding: 20px;
  position: relative;
  clip-path: polygon(0 0, 79% 0, 100% 100%, 0% 100%);
}

.matchup-header-left-logo {
  width: 90px;
  height: 90px;
  margin-right: 20px;
}

.matchup-header-left-info {
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: left;
}

.matchup-header-left-name {
  font-size: 20px;
  font-weight: bold;
}

.matchup-header-left-record {
  font-size: 16px;
}

.matchup-header-left-rank {
  font-size: 14px;
  margin-top: 5px;
}

.matchup-header-left-streak {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}

/* Middle Section (Date and Venue) */
.matchup-header-info {
  text-align: center;
  width: 15%; /* Increased width to ensure the time stays in line */
  font-size: 18px;
  white-space: nowrap; /* Prevents the text from breaking into multiple lines */
}

.matchup-header-date {
  font-size: 20px;
  font-weight: bold;
}

.matchup-header-venue {
  text-align: center; /* Center-align if needed */
  font-family: var(--font-main);
  font-size: 16px;
  color: var(--medium-text-color);
  margin-bottom: 10px;
}

.matchup-header-venue div:first-child {
  font-weight: 550;
  font-size: 18px;
  color: var(--dark-text-color);
}

.matchup-header-venue div:last-child {
  font-size: 14px;
  color: var(--medium-text-color);
}


/* Right Side (Home Team) with Border Container */
.matchup-header-right-border {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%; /* 5% wider for the outer border */
  position: relative;
  clip-path: polygon(19% 0, 100% 0, 100% 100%, 0 100%);
}

.matchup-header-right-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  padding: 20px;
  position: relative;
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
}

.matchup-header-right-logo {
  width: 90px;
  height: 90px;
  margin-left: 20px;
}

.matchup-header-right-info {
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: right;
}

.matchup-header-right-name {
  font-size: 20px;
  font-weight: bold;
}

.matchup-header-right-record {
  font-size: 16px;
}

.matchup-header-right-rank {
  font-size: 14px;
  margin-top: 5px;
}

.matchup-header-right-streak {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}

/* Win and Loss styling */
.matchup-header-left-w, .matchup-header-right-w {
  background-color: green;
  color: white;
  padding: 4px 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
}

.matchup-header-left-l, .matchup-header-right-l {
  background-color: rgb(247,64,55);
  color: white;
  padding: 4px 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
}
