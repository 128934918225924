.leaderboard-container {
  margin: 30px auto;  /* Center the container and add top margin */
  max-width: 1440px;
  padding: 40px;  /* Increased padding */
  font-family: 'Roboto', sans-serif;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-buttons button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  cursor: pointer;
  background-color: #1E2A39;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.toggle-buttons button.active {
  background-color: #0066cc;
}

.toggle-buttons button:hover {
  background-color: #005bb5;
}

.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 15px;  /* Increased padding for more space */
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.leaderboard-table th {
  color: #333;
  font-weight: bold;
  font-family: 'Montserrat';
  border-bottom: 2px solid #ddd;
}

.leaderboard-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.leaderboard-table tbody tr:nth-child(odd) {
  background-color: #fff;
}

.leaderboard-table tbody tr:hover {
  background-color: #f1f1f1;
}

.leaderboard-table tbody tr.rank-1,
.leaderboard-table tbody tr.rank-2,
.leaderboard-table tbody tr.rank-3,
.leaderboard-table tbody tr.rank-4,
.leaderboard-table tbody tr.rank-5,
.leaderboard-table tbody tr.rank-6 {
  background-color: #96f1ac1f;
}

.leaderboard-table tbody tr.rank-7,
.leaderboard-table tbody tr.rank-8,
.leaderboard-table tbody tr.rank-9,
.leaderboard-table tbody tr.rank-10 {
  background-color: #fff3cd83;
}

.leaderboard-table tbody tr.rank-11,
.leaderboard-table tbody tr.rank-12,
.leaderboard-table tbody tr.rank-13,
.leaderboard-table tbody tr.rank-14,
.leaderboard-table tbody tr.rank-15 {
  background-color: #ecbdc177;
}

.team-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.points-column {
  background-color: #e6f7f7;
  font-weight: bold;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-dot {
  width: 12px;
  height: 12px;
  margin: 0 2px;
  border-radius: 50%;
  display: inline-block;
}

.form-dot.win {
  background-color: #4CAF50;
}

.form-dot.draw {
  background-color: #FFC107;
}

.form-dot.loss {
  background-color: #f44336;
}

.team-rank {
  font-weight: bold;
  display: inline-block;
  width: 20px;
}

.rank-1, .rank-2, .rank-3, .rank-4, .rank-5, .rank-6, .rank-7 {
  color: rgb(70, 68, 68);
}

.rank-8, .rank-9, .rank-10, .rank-11, .rank-12, .rank-13, .rank-14, .rank-15 {
  color: rgb(70, 68, 68);
}

.conference-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;  /* Align items to the start */
  margin-bottom: 20px;  /* Increased bottom margin */
}

.conference-logo {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.conference-header span {
  font-size: 24px;
  font-family: 'Montserrat';
  margin-right: 10px;  /* Add space between text and arrow */
}

.toggle-arrow {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-right:50px;
  color: #333;
  font-weight: bold;
  transform: rotate(90deg);  /* Rotate the arrow to face down */
}
