/* TeamPointsDrilldown.css */
@import '../components/ChartCommon.css';

/* Keep the other styles specific to TeamPointsDrilldown */
.chart-wrapper {
  width: 100%;
  height: var(--chart-height);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: var(--chart-border-radius);
  font-family: var(--font-main), sans-serif;
  overflow: hidden;
  position: relative;
  padding-top: var(--chart-padding-top);
  color: var(--dark-text-color);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
}

.chart-title {
  font-size: 21px;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 19px;
  font-weight: bold;
}

.chart-container {
  width: 100%;
  height: calc(100% - 120px);
  flex-grow: 1;
}

/* Specific styles for the toggle container in TeamPointsDrilldown */
.toggle-container {
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-top: 0px;
}
