.available-players-buttons {
  display: flex;
  gap: 20px; /* Keep gap between buttons */
  overflow-x: auto;
  background-color: transparent;
  padding: 10px;
}

.available-players-matchup-btn {
  background-color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--font-main);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  flex-direction: row; /* Ensure row layout for logos */
}

.available-players-matchup-btn img {
  width: 42px; /* Slightly smaller logos */
  height: 42px;
}

.default-btn {
  background-color: #ffffff;
  color: black;
  font-weight: bold;
}

/* Team logo styling */
.available-players-matchup-btn img:first-of-type {
  margin-right: 5px; /* Reduce space between logos and @ symbol */
}

.available-players-matchup-btn img:last-of-type {
  margin-left: 5px; /* Reduce space between logos and @ symbol */
}

/* Styling for the @ symbol and time */
.matchup-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-main);
  font-size: 12px; /* Smaller font size */
  color: #333;
}

.matchup-at {
  font-size: 14px; /* Smaller font for @ symbol */
  font-weight: 600;
  color: #333;
  font-family: var(--font-main);
}

/* Time styling below the @ symbol */
.matchup-time span:last-child {
  font-size: 0.85em; /* Smaller font for the time */
  margin-top: 3px; /* Add space below the @ symbol */
  color: #666; /* Lighter color for the time */
  font-family: var(--font-main);
}
