.parlay-builder-container {
  padding: 20px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
  border: 1px solid var(--light-text-color);
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  font-family: var(--font-main)
}

.parlay-builder-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.parlay-builder-header h3 {
  font-family: var(--font-main);
  color: var(--dark-text-color);
  font-size: 24px;
  margin: 0;
}

.parlay-builder-top-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.parlay-builder-refresh-btn {
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: var(--main-color);
}

.parlay-builder-suggest-players {
  background-color: var(--main-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--font-roboto);
}

.parlay-builder-legs {
  margin-top: 10px;
  font-size: 1em;
  font-weight: normal;
  color: var(--medium-text-color);
}

.parlay-builder-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.parlay-builder-table th,
.parlay-builder-table td {
  font-family: var(--font-roboto);
  color: var(--medium-text-color);
  border-bottom: 1px solid var(--light-text-color);
  font-size: 16px;
  padding: 10px;
  text-align: left;
  vertical-align: middle;
  position: relative;
}

.parlay-builder-table th {
  color: var(--dark-text-color);
  font-weight: bold;
}

.parlay-builder-player-info {
  display: flex;
  align-items: center;
}

.parlay-builder-player-logo-container {
  position: relative;
  border-radius: 8px;
  display: flex;
  max-width: 60px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 10px;
}
.parlay-builder-refresh-btn {
  color: var(--main-color); /* For the icon color */
  background-color: transparent; /* Optional, for no background */
  border: none; /* Optional, for removing border */
  cursor: pointer; /* Optional, for better user experience */
}

.parlay-builder-team-logo-top-left {
  position: absolute;
  top: 15%;
  left: 0px;
  width: 35px;
  height: 35px;
  z-index: 0;
}

.parlay-builder-player-logo {
  width: 75px;
  margin-top: 10px;
  height: 50px;
  z-index: 0;
}

.parlay-builder-details {
  display: flex;
  flex-direction: column;
}

.parlay-builder-name {
  font-weight: bold;
  white-space: nowrap;
}

.parlay-builder-points {
  font-size: 0.9em;
  color: var(--medium-text-color);
}

.parlay-builder-remove-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--main-color);
  font-size: 1.2em;
  padding: 5px;
}

.parlay-builder-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.parlay-builder-summary {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.parlay-builder-separator {
  border-top: 1px solid var(--light-text-color);
  width: 100%;
  margin: 10px 0;
}

.parlay-builder-return {
  font-weight: bold;
  font-size: 1.1em;
}

.parlay-builder-confirm-btn {
  background-color: var(--main-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--font-roboto);
}