.l15-stats-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns content to the left */
  justify-content: flex-start;
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  overflow: hidden;
  font-family: var(--font-main);
  background-color: var(--team-color);
}

.l15-table-header {
  background-color: var(--medium-text-color);
  width: 100%; /* Ensure it takes up the full width */

}

.l15-header-title {
  font-size: 21px;
  font-weight: 700;
  font-family: var(--font-main);
  color: var(--dark-text-color);
  margin-top:30px;
  margin-left:15px;
  margin-bottom: -10px;
  text-align: left; /* Ensures text is aligned left */
}



.l15-header-icon::before {
  content: '';
  font-size: 19.25px;
  margin-left: 7px;
}

.l15-stats-table {
  width: 100%;
  border-collapse: collapse;
}

.l15-stats-table th,
.l15-stats-table td {
  
  text-align: left;
  white-space: nowrap;
}

.l15-stats-table th {
  color: var(--dark-text-color)  ;
  font-weight: 550;
  background-color: inherit;
  padding: 5.25px 17.5px;
  border-bottom: 2px solid rgb(0, 0, 0);
  font-family: var(--font-main);
  font-size: 16.625px;
}

.l15-stats-table td {
  color: var(--medium-text-color)  ;  ;
  font-size: 15px;
  padding: 7.25px 15.75px;
  font-weight: 450; /* Make the metric column slightly bolder */
  border-bottom: 1px solid rgb(236, 236, 236);
}

.l15-stats-table td:first-child {
  font-weight: 500; /* Make the metric column slightly bolder */
}

.l15-even-row {
  background-color: white;
}

.l15-odd-row {
  background-color: white;
}

.last-15 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.last-15-item {
  flex: 1 1 262.5px;
  max-width: calc(33.333% - 17.5px);
  margin: 10px;
  border-radius: 12px;
  overflow: hidden;
  background-color: var(--team-color);
  color: white;
  transition: flex 0.3s ease;
}

@media (max-width: 900px) {
  .last-15-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .last-15-item {
    flex: 1 1 262.5px;
    max-width: 100%;
  }
}

/* Arrow styles */
.l15-arrow-up {
  color: green;
  font-size: 16px;
  vertical-align: top;
}

.l15-arrow-down {
  color: rgb(247,64,55);
  font-size: 16px;
  vertical-align: top;
}

/* Percentage difference styles */
.l15-percentage-diff {
  font-size: 12px;
  vertical-align: middle;
}
