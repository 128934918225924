.select-player-parent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 740px;
  margin: 0 auto;
}

.select-player-row-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  gap: 10px; /* Adds gap between two visuals in the same row */
}

.select-player-card {
  display: flex;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  position: relative;
  max-width: 48%; /* Ensure cards fit within the row-container */
  flex: 1; /* Allow flexibility */
  max-width: 48%; /* For two cards */
}

.select-player-card-left {
  flex: 1;
  padding-right: 0px;
  border-right: 1px solid #eaeaea;
  max-width: 200px;
}

.select-player-card-right {
  flex: 1;
  padding: 10px 15px;
  width: 180px;
}

.select-player-button-container {
  display: flex;
  margin-left: -15px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2px; /* Smaller gap between buttons */
  width: 100%; /* Ensure buttons stay within the visual */
  margin-bottom: 10px;
}

.select-player-top-button {
  background: var(--muted-color); /* Placeholder for muted teamColor */
  color: white;
  border: none;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px; /* Adjust size for better appearance */
  text-align: center;
  transition: background 0.3s;
}

.select-player-top-button.active {
  background: var(--active-color); /* Placeholder for active teamColor */
}

.select-player-info-header {
  display: flex;
  align-items: center;
  height: 130px;
  border-radius: 10px 10px 0 0;
  position: relative;
  background: linear-gradient(to top, rgba(30, 30, 30, 0.8), transparent);
}

.select-player-image {
  height: 130px;
  width: 170px;
  margin-left: 15px;
  z-index: 20; /* Ensure select-player image is above the team logo */
}

.select-player-team-logo {
  height: 90px;
  width: 90px;
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: 10; /* Ensure team logo is behind the select-player image */
}

.select-player-details {
  padding: 0px 7px;
  margin-top: 10px;
}

.select-player-details h2 {
  font-size: 22px; /* Adjusted font size */
  margin: 0;
  color: var(--dark-text-color);
  font-family: var(--font-main);
}

.select-player-details .select-player-matchup {
  margin-top: 5px;
  color: var(--medium-text-color);
  font-size: 16px; /* Adjusted font size */
  display: flex;
  justify-content: space-between;
}

.select-player-game-time {
  color: var(--medium-text-color);
  font-size: 14px;
  margin-right: 5px;
  margin-top: 1px;
}

.select-player-referee {
  color: var(--dark-text-color);
  font-size: 14px;
  margin-top: 5px;
}

.select-player-details hr {
  border: none;
  border-bottom: 1px solid #eaeaea;
  margin: 10px 0;
}

.select-player-prediction-pick-container {
  margin-top: -5px;
  display: flex;
  justify-content: space-between;
  padding: 2px 5px;
  align-items: center;
}

.select-player-prediction,
.select-player-pick {
  font-weight: bold;
  font-size: 16px;
  padding: 5px 0px;
  color: var(--dark-text-color);
}

.select-player-prediction-value,
.select-player-pick-value,
.select-player-prop,
.select-player-line-value {
  text-align: right;
  font-size: 18px;
  font-weight: 700;
  color: var(--dark-text-color);
}

.select-player-feature-section {
  margin-top: 15px;
  margin-bottom: 10px;
}

.select-player-feature-section h3 {
  margin: 0;
  font-size: 18px; /* Increased font size */
  color: var(--dark-text-color);
  margin-bottom: 5px;
}

.select-player-bar-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: 100%; /* Adjusted bar width */
}

.select-player-bar {
  position: relative;
  height: 8px;
  border-radius: 4px;
  background: #dcdcdc;
  width: 100%; /* Adjust the width to fit better under title */
}

.select-player-line-dot {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transition: left 0.3s ease;
}

.select-player-bar-value {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 550;
  color: var(--dark-text-color);
}

/* Add a comment input field for each player card */
.select-player-comment-input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid var(--light-text-color);
  font-family: var(--font-roboto);
  font-size: 14px;
}

/* Add a plus button for adding players */
.select-player-add-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: green;
  color: white;
  border: none;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px 10px;
}

.select-player-add-btn:hover {
  background: darkgreen;
}

.select-player-remove-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  z-index: 50;
  color: white;
  border: none;
  padding: 3px 6px; /* Smaller button size */
  cursor: pointer;
  transition: background 0.3s;
}
/* Add a comment section */
.select-player-comment {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--medium-text-color);
  text-align: left; /* Ensure it spans both columns */
  display: block;
  margin-top: 15px;
}

/* Add a plus button for saving players to cache */
.select-player-add-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: green;
  color: white;
  border: none;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px 10px;
}

.select-player-add-btn:hover {
  background: darkgreen;
}

.select-player-remove-btn:hover {
  background: darkred;
}

/* The main parent container */
.select-player-parent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  position: relative; /* To position the popup relative to the parent */
}

/* Styling for the popup */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  padding: 30px;
  z-index: 1000;
  max-width: 400px;
  width: 100%;
  text-align: center;
  font-family: var(--font-roboto);
}

/* Dark overlay behind the popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

/* Popup message text */
.popup p {
  font-size: 18px;
  color: var(--dark-text-color);
  margin-bottom: 20px;
}

/* Popup buttons container */
.popup-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

/* Styling for the Yes/No buttons */
.popup-buttons button {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.popup-buttons button:hover {
  opacity: 0.85;
}

.popup-buttons button:first-child {
  background-color: green;
  color: white;
}

.popup-buttons button:last-child {
  background-color: rgb(247,64,55);
  color: white;
}

/* Styling for the player cards remains the same */
.select-player-row-container:first-of-type {
  margin-top: 100px;
}
.select-player-row-container.single-card-row {
  justify-content: center; /* Center the single card */
}
.select-player-row-container.single-card-row .select-player-card {
  max-width: 100%; /* Full width for single card */
}

.select-player-row-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  gap: 20px;
}

/* Styles for player cards and elements remain unchanged */
