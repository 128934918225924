.team-vs-team-table-container {
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Added padding to the bottom of the table container */
}

.team-vs-team-table-title {
    text-align: left;
    font-size: 22px;
    margin-bottom: 10px;
    margin-left:15px;
    font-family: var(--font-main);
    color: var(--dark-text-color);
}

.team-vs-team-table {
    width: 100%;
    border-collapse: collapse;
}

.team-vs-team-table-header {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    font-family: var(--font-main);
    color: var(--dark-text-color);
    padding-left: 10px; /* Moved table header 10px to the left */
}

.team-vs-team-table-team-logo {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
}

.team-vs-team-table-header-team {
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    font-family: var(--font-main);
    color: var(--dark-text-color);
}

.team-vs-team-table-header-team-wrapper {
    display: flex;
    align-items: center;
    justify-content: left;
}

.team-vs-team-table td, .team-vs-team-table th {
    
    border-bottom: 1px solid var(--light-text-color);
    text-align: left;
    font-family: var(--font-main);
    color: var(--medium-text-color);
}

.team-vs-team-table td {
    font-size: 18px;
    padding: 10px 10px;
}
.team-vs-team-table th {
    font-size: 18px;
    padding: 3px 5px;
}
/* Make the team stats italic */
.team-vs-team-table td:nth-child(2), 
.team-vs-team-table td:nth-child(3) {
    transform: skewX(-10deg);
    font-family: var(--font-roboto);
    font-weight: 550;
}

.team-vs-team-table td:first-child {
    font-weight: 550;
    font-size: 17px;
    font-family: var(--font-main);
}

.team-vs-team-table-header td, .team-vs-team-table-header th {
    border-bottom: none;
}