.ticket-analyzer-wrapper {
  width: 1440px;
  margin: 0 auto;
  margin-top: 70px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.ticket-analyzer-layout {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.main-content {
  display: flex;
  justify-content: space-between;
  gap:0px;
  width: 100%;
}

.selected-players-container {
  min-width: 840px;
  max-width:840px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.parlay-builder-container {
  min-width: 500px;
  max-width:500px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
