:root {
  --main-color: var(--main-color);  /* Purple */
  --detail-color: #2cccc4;
  --font-roboto: "Roboto";
  --font-main: "Montserrat";
  --dark-text-color: #4F4F4F;
  --medium-text-color: #666666;
  --light-text-color: #d8d5d5a9;
}

/* Outer Container */
.live-data-container {
  width: 95%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  font-family: var(--font-main);
  color: var(--dark-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
}

h1 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: var(--main-color);
  text-align: center;
}

/* Filters */
.live-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
  width: 100%;
}

.live-styled-input,
.live-styled-select {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
  transition: border-color 0.3s ease;
  font-family: var(--font-roboto, sans-serif);
  color: var(--medium-text-color);
}

.live-styled-input:focus,
.live-styled-select:focus {
  border-color: var(--main-color);
}

/* ===== DESKTOP TABLE LAYOUT (9 columns) =====
   1 => Player
   2 => Min
   3 => Clock
   4 => Status
   5 => Feature (Prop)
   6 => Threshold (Line)
   7 => Pick
   8 => Bar
   9 => Remove
*/
.live-player-header,
.live-player-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1.2fr 1fr 2fr 2fr 2fr 4fr 1fr; 
  align-items: center;
  width: 100%;
  margin: 5px 0;
  border-radius: 8px;
  box-sizing: border-box;
}

.live-player-header {
  background-color: #e7e7e7;
  border: 1px solid #ccc;
  font-weight: 600;
  padding: 12px;
  text-transform: uppercase;
}

.live-player-header > div {
  text-align: center;
  padding: 5px;
}

.live-player-row {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 10px;
  transition: background-color 0.3s;
}

.live-player-row:hover {
  background-color: #f7f7f7;
}

/* Desktop cells */
.live-player-info,
.live-player-minutes,
.live-player-gametime,
.live-player-status,
.live-player-feature,
.live-player-threshold,
.live-player-pick,
.live-player-bar-header,
.live-player-remove {
  text-align: center;
  padding: 5px;
  word-wrap: break-word;
}

/* narrower threshold/pick on desktop */
.live-player-threshold input {
  width: 60px;
  text-align: center;
}
.live-player-feature select,
.live-player-pick select {
  width: 80px;
}

/* Performance bar (desktop) */
.live-player-bar-container {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: inset 0 2px 3px rgba(0,0,0,0.15);
  display: flex;
  align-items: center;
}
.live-player-bar-fill {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
  transition: width 0.3s ease;
  background-color: #ccc;
}
.live-player-bar-value {
  margin-right: 8px;
}

/* Remove button */
.live-remove-btn {
  background-color: transparent;
  border: none;
  color: #e74c3c;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s;
}
.live-remove-btn:hover {
  color: #c0392b;
}

/* Player view container */
.live-player-view {
  width: 100%;
}

/* Slightly smaller => keep table but reduce spacing */
@media (max-width: 900px) {
  .live-player-header,
  .live-player-row {
    grid-template-columns: 1.7fr 1fr 1fr 1fr 2fr 2fr 2fr 3fr 1fr;
    font-size: 0.9rem;
  }
  .live-player-header {
    padding: 10px;
  }
  .live-player-row {
    padding: 8px;
  }
}

/* ===== MOBILE < 600px => 4 ROWS EXACTLY LIKE THE IMAGE =====
   Row 1 => [ Name (center) + Remove top-right ]
   Row 2 => [ Line, Pick, Prop ] horizontally 
   Row 3 => [ Live: bar ] 
   Row 4 => [ Min, Clock, Status ] horizontally
*/
@media (max-width: 600px) {
  /* Hide desktop header */
  .live-player-header {
    display: none !important;
  }

  .live-player-row {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 20px auto;
    max-width: 360px;
    background-color: #fff;
    box-shadow: 0 3px 8px rgba(0,0,0,0.1);
    padding: 16px;
    position: relative;
  }
  .live-player-row:hover {
    background-color: #fff;
  }

  /* Row 1 => Player name + remove icon top-right */
  .live-player-info {
    order: 1;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--main-color);
    text-align: center;
    width: 100%;
  }
  .live-player-remove {
    order: 1;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  /* Row 2 => 3 columns: line, pick, prop side by side */
  /* We place them in order 6 => line, 7 => pick, 5 => prop. 
     We'll do "display: flex" on a container or rely on each with inline-block. 
  */
  .live-player-threshold {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
  }
  .live-player-threshold::before {
    content: "Line:";
    font-weight: bold;
    color: var(--main-color);
    margin-bottom: 3px;
  }
  .live-threshold-input {
    width: 40px;
    text-align: center;
  }

  .live-player-pick {
    order: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
  }
  .live-player-pick::before {
    content: "Pick:";
    font-weight: bold;
    color: var(--main-color);
    margin-bottom: 3px;
  }
  .live-pick-select {
    width: 60px;
    text-align: center;
  }

  .live-player-feature {
    order: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
  }
  .live-player-feature::before {
    content: "Prop:";
    font-weight: bold;
    color: var(--main-color);
    margin-bottom: 3px;
  }
  .live-feature-select {
    width: 60px;
    text-align: center;
  }

  /* Make a small container or let them appear in order 2-> line, 3-> pick, 4-> prop horizontally. 
     We can do inline-block or a small row. We'll do row2: 
  */
  .live-player-threshold,
  .live-player-pick,
  .live-player-feature {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
  }

  /* Row 3 => "Live:" + bar => single row (col8) */
  .live-player-bar-container {
    order: 5;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    width: 200px;
    height: 14px;
    border-radius: 7px;
    background-color: #eee;
    margin: 0 auto; /* center horizontally */
    position: relative;
  }
  .live-player-bar-fill {
    border-radius: 7px;
    font-size: 0.75rem;
  }
  .live-player-bar-container::before {
    content: "Live:";
    font-weight: bold;
    color: var(--main-color);
    position: static;
    margin-right: 6px;
  }

  /* Row 4 => 3 columns: min (col2), clock (col3), status (col4) side by side */
  .live-player-minutes {
    order: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
  }
  .live-player-minutes::before {
    content: "Min:";
    font-weight: bold;
    color: var(--main-color);
    margin-bottom: 3px;
  }

  .live-player-gametime {
    order: 7;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
  }
  .live-player-gametime::before {
    content: "Clock:";
    font-weight: bold;
    color: var(--main-color);
    margin-bottom: 3px;
  }

  .live-player-status {
    order: 8;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
  }
  .live-player-status::before {
    content: "Status:";
    font-weight: bold;
    color: var(--main-color);
    margin-bottom: 3px;
  }
}
