/* Container */
.historical-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px;
  font-family: var(--font-main);
  color: #333;
  box-sizing: border-box;
}

.historical-results-container h1 {
  text-align: center;
  font-size: 2rem;
  color: var(--main-color, #0066cc);
  margin-bottom: 20px;
}

/* ---------------------------------
   FILTERS (hr-filters, hr-filter-item)
---------------------------------- */
.hr-filters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  font-family: var(--font-main);
  flex-wrap: wrap; /* Let it wrap on smaller screens */
}

.hr-filter-item {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 90px;
  font-family: var(--font-main);
}

.hr-filter-item label {
  font-weight: 600;
  margin-bottom: 3px;
  color: var(--main-color); /* main color or your choice */
}

/* Multi-select styling */
.hr-multi-select-control {
  position: relative;
  background: #f2f2f2;
  padding: 6px 35px 6px 10px;
  border-radius: 4px;
  user-select: none;
  min-width: 150px;
  max-width: 100px;
  cursor: pointer; /* So user knows they can click it */
  color: var(--main-color);
}

.hr-multi-select-control:hover {
  background: #e4e4e4;
}

.hr-multi-select-control span {
  margin-right: 20px;
}

/* Arrow icon */
.hr-multi-select-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--main-color); /* arrow color */
  pointer-events: none;
}

.hr-multi-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px;
  margin-top: 2px;
  z-index: 999;
  width: 150px;
}

.hr-option {
  margin-bottom: 4px;
}

.hr-option label {
  cursor: pointer;
  font-weight: normal;
  color: var(--main-color);
  font-family: var(--font-main);
}


/* ---------------------------
   KPI Container
---------------------------- */
.kpi-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;
  margin-bottom: 30px;
  width: 100%;
  max-width: 1440px;
}

/* Individual KPI Card */
.kpi-card {
  flex: 1 1 120px;
  min-width: 120px;
  max-width: 180px;
  text-align: center;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.kpi-title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1rem;
}

.kpi-accuracy,
.kpi-profit,
.kpi-games {
  margin: 5px 0;
}

/* ---------------------------
   Table Wrapper
---------------------------- */
.table-wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 20px auto;
  overflow-x: auto; /* Horizontal scroll if table wide */
}

/* Actual Table */
.historical-results-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.historical-results-table th,
.historical-results-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 14px;
}

.historical-results-table th {
  background-color: var(--main-color, #0066cc);
  color: #fff;
}

/* Profitable vs Non-Profitable Rows */
.profitable {
  background-color: #d4edda;
  color: #155724;
}

.non-profitable {
  background-color: #f8d7da;
  color: #721c24;
}

/* Loading */
.loading {
  font-size: 18px;
  color: var(--main-color, #0066cc);
  margin-top: 40px;
  text-align: center;
}

/* Responsive for Mobile */
@media screen and (max-width: 768px) {
  .hr-filters {
    flex-direction: column;
    align-items: center;
  }
 .hr-multi-select-control, .hr-filter-item {
  align-items: center; 
  text-align: left;
}
  .hr-filter-item {
    min-width: 100%;
  }

  .kpi-card {
    max-width: none;
    width: 100%;
  }

  .historical-results-table {
    font-size: 12px;
  }
}
