.team-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-selected-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 2;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    z-index: 5;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-name {
    margin-top: 20px;
    font-size: 1em;
    z-index: 5;
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-dropdown-arrow {
    color: white;
    margin-top: 30px;
    font-size: 14px;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-autocomplete-container {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    border-radius: 18px;
    padding: 2px 0;
    font-size: 90%;
    z-index: 999;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-autocomplete-item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 18px;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-autocomplete-item:hover,
  .team-card-autocomplete-item.highlighted,
  .team-card-autocomplete-item.clicked {
    background-color: var(--highlight-color);
    color: black;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-autocomplete-search-input {
    width: 100%;
    padding: 10px;
    border: none;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    font-size: 16px;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-autocomplete-search-input::placeholder {
    color: white;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-autocomplete-scroll-container {
    max-height: 200px;
    overflow-y: auto;
    background: inherit;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-autocomplete-scroll-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .team-card-autocomplete-scroll-container::-webkit-scrollbar-track {
    background: inherit;
  }
  
  .team-card-autocomplete-scroll-container::-webkit-scrollbar-thumb {
    background-color: var(--highlight-color);
    border-radius: 10px;
    border: 2px solid transparent;
  }
  
  .team-card-autocomplete-scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: lightgray;
  }
  
  .team-card-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    z-index: 3;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-logo {
    width: 175px; /* Adjusted width from 200px */
    height: 175px; /* Adjusted height from 200px */
    object-fit: contain;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-middle-line-container {
    position: absolute;
    height: 90%;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    left: 50%;
    width: 256.25px; /* Adjusted width from 75% */
    transform: translateX(-50%);
    background-color: white;
    clip-path: polygon(50% 0%, 100% 0%, 100% 80%, 50% 100%, 0% 80%, 0% 0%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-middle-line {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.11), rgba(0, 0, 0, 0.678));
    clip-path: polygon(50% 0%, 100% 0%, 100% 80%, 50% 100%, 0% 80%, 0% 0%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  .team-card-record {
    margin-top: 192.5px; /* Adjusted margin-top from 220px */
    color: white;
    font-size: 25px;
    font-weight: bold;
    font-family: var(--font-main); /* Apply Montserrat font */
  }
  
  @media (max-width: 900px) {
    .team-card-middle-line-container {
      width: 590.625px; /* Adjusted width from 67.5% */
    }
    .team-card-logo {
      width: 153.125px; /* Adjusted width from 175px */
      height: 153.125px; /* Adjusted height from 175px */
    }
  }
  
  @media (max-width: 600px) {
    .team-card-middle-line-container {
      width: 437.5px; /* Adjusted width from 50% */
    }
    .team-card-logo {
      width: 131.25px; /* Adjusted width from 150px */
      height: 131.25px; /* Adjusted height from 150px */
    }
  }
  
  @media (max-width: 300px) {
    .team-card-middle-line-container {
      width: 328.125px; /* Adjusted width from 37.5% */
    }
    .team-card-logo {
      width: 87.5px; /* Adjusted width from 100px */
      height: 87.5px; /* Adjusted height from 100px */
    }
  }
  