.shooting-chart-container {
  width: 100%;
  height: 100%;

  background-color: var(--background-color, #ffffff);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

/* Responsive adjustments */
@media (max-width: 768px) {
  .shooting-chart-container  {
    margin: 0px;
  }
}

@media (max-width: 480px) {
  .shooting-chart-container  {
    margin: 0px;
  }
}