.minute-chart-container {
    width: 100%;
    height: 350px;
  
    background-color: var(--background-color, #ffffff);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  }
  
  .minute-chart-container .echarts-for-react {
    height: 95% !important;
    width: 100% !important;
  }
  
  @media (max-width: 768px) {
    .minute-chart-container {
      width: 100%;
      height: 300px;
      margin: 10px 0;
    }
  }
  
  @media (max-width: 480px) {
    .minute-chart-container {
      height: 250px;
    }
  }