/* Overall layout container */
.layout-container {
  padding-top: 20px;
  width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Gap between header and columns */
}

/* Matchup Header */
.matchup-header {
  text-align: center;
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Small box shadow */
  font-family: var(--font-main);
  color: var(--dark-text-color);
}

/* Columns container (holds left, middle, and right columns) */
.columns-container {
  display: flex;
  gap: 10px; /* Gap between columns */

}

/* Left Column */
.left-column {
  display: flex;
  flex-direction: column;
  width: 28%; /* 28% of total width for the left column */
  gap: 15px;
}

.left-container {
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Small box shadow */
  height: 50%; /* Each container takes half the height of the left column */
}

/* Middle Column */
.middle-column {
  display: flex;
  flex-direction: column;
  width: 44%; /* 44% of total width for the middle column */
  gap: 10px;
}

.middle-container {
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Small box shadow */
}

.middle-top-1,
.middle-top-2 {
  height: 48.5%; /* Each of the top two containers takes up half the height */
}

.middle-bottom {
  height: 100%; /* The bottom container takes full height */
}

/* Right Column */
.right-column {
  width: 28%; /* 28% of total width for the right column */
}

.right-container {
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Small box shadow */
  height: 100%; /* Full height for the right container */
}

/* View Buttons Container */
.view-buttons-container {
  display: flex;
  justify-content: space-between; /* Align left and right buttons to edges */
  align-items: center;
  margin-top: -50px;
}

/* View Buttons (Left) */
.view-buttons {
  display: flex;
  gap: 10px;
}

/* View Buttons (Right) */
.right-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end; /* Align buttons to the right */
}

/* Buttons Styling */
.view-buttons button,
.right-buttons button {
  padding: 8px 15px;
  background-color: var(--main-color);
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
}

.view-buttons button.active,
.right-buttons button.active {
  background-color: var();
}

.view-buttons button:hover,
.right-buttons button:hover {
  background-color: var(--main-color);
}

/* Content Areas */
.matchup-stats-content,
.starting-five-content,
.box-score-content,
.players-content,
.stream-content {
  text-align: left;
  padding: 20px;
  background-color: white;

  
}

/* Content Headings */
.matchup-stats-content h2,
.starting-five-content h2,
.box-score-content h2,
.players-content h2,
.stream-content h2 {
  font-family: var(--font-main);
  color: var(--dark-text-color);
}
