.similar-player-performance-container {
  width: 97%;
  background-color: #fff;
  overflow: hidden;
  padding: 8px;
}

.similar-player-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  background-color: #fff;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title-container h2 {
  font-size: 24px;
  margin: 0px;
  font-family: var(--font-main);
  color: var(--dark-text-color);
}

.title-container h3 {
  font-size: 18px;
  margin: 5px 0 0;
  font-family: var(--font-main);
  color: var(--medium-text-color);
}

.fetch-similar-players-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  margin-top:30px;
  border-radius: 20px;
  padding: 5px 5px;
  font-size: 13px;
  font-weight: 550;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.fetch-similar-players-button:hover {
  background-color: #357ae8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.fetch-similar-players-button:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.button-icon {
  margin-right: 8px;
  font-size: 18px;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.similar-player-performance-table {
  width: 100%;
  border-collapse: collapse;
}

.similar-player-performance-table th,
.similar-player-performance-table td {
  text-align: center;
  font-family: var(--font-roboto);
  color: var(--medium-text-color);
  padding: 5px 0px;
}

.similar-player-performance-table tr {
  border-bottom: 1px solid var(--light-text-color);
}

.similar-player-performance-table th {
  border-bottom: 1px solid var(--light-text-color);
  font-size: 18px;
  color: var(--dark-text-color);
  padding: 8px;
  font-weight: 650;
}

.similar-player-performance-table tbody tr:hover {
  background-color: #f1f1f1;
}

.similar-player-player-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.similar-player-performance-player-image {
  width: 115px;
  height: 80px;
  z-index: 1;
}

.similar-player-team-logo {
  width: 45px;
  height: auto;
  position: absolute;
  top: 10px;
  right: 2px;
  z-index: 2;
}

.similar-player-performance-player-position {
  font-size: 14px;
  font-weight: bold;
  color: var(--medium-text-color);
  position: absolute;
  top: 22px;
  left: 18px;
  z-index: 3;
}

.player-name-box {
  min-width: 115px;
  display: flex;
  justify-content: center;
  background-color: #fdfdfd;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
}

.similar-player-performance-player-name {
  font-size: 16px;
  font-weight: 545;
  text-align: center;
  color: var(--dark-text-color);
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 100;
}

.similar-player-performance-player-number {
  font-size: 14px;
  color: var(--medium-text-color);
}

.similar-player-performance-player-number.green {
  color: var(--team-color-primary);
}

.similar-player-performance-player-number.red {
  color: var(--team-color-secondary);
}

.similar-player-divider {
  border: none;
  height: 1px;
  background-color: var(--light-text-color);
}

.similar-player-chart-container {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
}

/* Responsive adjustments */
@media screen and (max-width: 900px) {
  .title-container h2 {
    font-size: 22px;
  }
  
  .title-container h3 {
    font-size: 16px;
  }
  
  .fetch-similar-players-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .similar-player-performance-player-image {
    width: 80px;
    height: 52px;
  }

  .player-name-box {
    min-width: 80px;
  }

  .similar-player-performance-player-name {
    font-size: 14px;
  }

  .similar-player-performance-player-number {
    font-size: 12px;
  }

  .similar-player-team-logo {
    width: 30px;
  }

  .similar-player-performance-player-position {
    font-size: 10px;
  }
}

@media screen and (max-width: 600px) {
  .similar-player-header-container {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .title-container {
    margin-bottom: 10px;
  }
  
  .title-container h2 {
    font-size: 20px;
  }
  
  .title-container h3 {
    font-size: 14px;
  }
  
  .fetch-similar-players-button {
    font-size: 12px;
    padding: 6px 12px;
  }

  .similar-player-performance-player-image {
    width: 60px;
    height: 39px;
  }

  .player-name-box {
    min-width: 60px;
  }

  .similar-player-performance-player-name {
    font-size: 12px;
  }

  .similar-player-performance-player-number {
    font-size: 10px;
  }

  .similar-player-team-logo {
    width: 20px;
  }

  .similar-player-performance-player-position {
    font-size: 8px;
  }
}

@media screen and (max-width: 300px) {
  .title-container h2 {
    font-size: 18px;
  }
  
  .title-container h3 {
    font-size: 12px;
  }
  
  .fetch-similar-players-button {
    font-size: 10px;
    padding: 4px 8px;
  }

  .similar-player-performance-player-image {
    width: 50px;
    height: 32.5px;
  }

  .player-name-box {
    min-width: 50px;
  }

  .similar-player-performance-player-name {
    font-size: 10px;
  }

  .similar-player-performance-player-number {
    font-size: 8px;
  }

  .similar-player-team-logo {
    width: 12px;
  }

  .similar-player-performance-player-position {
    font-size: 6px;
  }
}