/* PreviousBets.css */

.previous-bets-container {
  color: var(--dark-text-color);
  font-family: var(--font-main);
  padding: 0px 20px;
  width: 100%;
}

.bets-table {
  width: 100%;
  border-collapse: collapse;
}

.bets-table th,
.bets-table td {
  padding: 12px 15px;
  font-family: var(--font-roboto);
  text-align: left;
  vertical-align: middle;
}

.bets-table th {
  color: var(--dark-text-color);
  font-weight: bold;
}

.bets-table td {
  color: var(--medium-text-color);
}

/* General styles for results and icons */
.ticket-result span {
  margin-left: 8px; /* 3px gap between result text and icon */
  display: inline-block;
  vertical-align: middle; /* Vertically align icon with text */
}

.player-details-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  margin-top: 10px;
}

.player-details-table th,
.player-details-table td {
  padding: 12px 15px;
  text-align: left;
}

.player-details-table th {
  background-color: #f5f3f3;
  color: var(--dark-text-color);
}

.player-details-table td {
  background-color: white; /* Ensure all rows have white background */
  color: var(--medium-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Result column styling */
.player-details-table td.result {
  padding: 10px 15px;
  background-color: white; /* Ensure no background color */
  border: none;
}

/* Ensure icons are placed close to the result text with 3px gap */
.player-details-table td.result span {
  margin-left: 3px; /* 3px gap between result text and icon */
  margin-top: -4px;
  display: inline-block;
  vertical-align: middle; /* Vertically align the icon with the text */
}

.player-details-table td {
  vertical-align: middle; /* Ensures content in cells is vertically centered */
}

.icon {
  font-size: 1.2em;
  vertical-align: middle; /* Ensure icons align with the text */
}

.icon.green {
  color: green;
}

.icon.red {
  color: rgb(247,64,55);
}

/* Table row styles */
.bet-row {
  border-bottom: 1px solid var(--light-text-color);
  cursor: pointer;
}

.bet-row:hover {
  background-color: #f9f9f9;
}

.expanded-row {
  border-top: 1px solid var(--light-text-color);
}

.bets-table td {
  vertical-align: middle; /* Keeps content centered */
}

/* Date picker styles */
.date-picker-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.date-picker-container label {
  margin-right: 10px;
}

.date-picker-container .react-datepicker-wrapper {
  margin-right: 20px;
}

.apply-filter-btn {
  padding: 10px 20px;
  background-color: var(--button-color);
  color: white;
  border: none;
  cursor: pointer;
}

.apply-filter-btn:hover {
  background-color: var(--button-hover-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .bets-table, .player-details-table {
    font-size: 12px;
  }

  .date-picker-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .date-picker-container label,
  .date-picker-container .react-datepicker-wrapper,
  .apply-filter-btn {
    margin-bottom: 10px;
  }
}
.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-inputs {
  display: flex;
  align-items: center;
}

.filter-inputs label {
  margin-right: 10px;
}

.filter-inputs > * {
  margin-right: 10px;
}
