.top-picks-container {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 12px;
  padding: 10px 10px;
  overflow: hidden;
}

.top-picks-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.top-picks-header h2 {
  font-family: var(--font-main);
  font-size: 24px;
  font-weight: 600;
  color: var(--dark-text-color);
  text-align: left;
}
.top-picks-restricted {
  text-align: center;
  margin-top: 20px;
}

.subscribe-button {
  background-color: var(--main-color);
  color: gold;
  font-family: var(--font-main);
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #1ab3ac;
}

.prop-filter-select {
  padding: 6px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: var(--font-roboto);
}

.top-picks-table-container {
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  max-height: 453px; /* Adjust as needed */
  overflow-y: auto;
}

.top-picks-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}

.top-picks-table td, 
.top-picks-table th {
  padding: 22px 6px;
  border-bottom: 1px solid #e0e0e0;
  font-family: var(--font-roboto);
  color: var(--medium-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.top-picks-table td {
  text-align: center;
  font-size: 16px;
}
.top-picks-table th {
  text-align: center;
  font-family: var(--font-main);
  font-size: 15px;
  font-weight: 600;
  color: var(--dark-text-color);
  text-transform: uppercase;
  background-color: #ffffff;
}

.top-picks-table th:first-child,
.top-picks-table td:first-child {
  width: 40%;
  position: relative;
  text-align: left;
  font-size: 18px;
}

.top-picks-table th:nth-child(2),
.top-picks-table td:nth-child(2),
.top-picks-table th:nth-child(3),
.top-picks-table td:nth-child(3),
.top-picks-table th:nth-child(4),
.top-picks-table td:nth-child(4) {
  width: 19.33%;
}

.top-picks-player {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 10;
  background-color: #ffffff;
  padding-left: 8px;
}

.player-logo-container {
  width: 55px;
  height: 50px;
  min-width: 60px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.player-logo {
  margin-top: 8px;
 
  height: 55px;
  object-fit: cover;
}

.player-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 60px);
}

.player-name {
  font-weight: 600;
  font-size: 15px;
  color: var(--dark-text-color);
  margin-right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.player-prop {
  font-size: 13px;
  color: var(--medium-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Hover effect for table rows */
.top-picks-table tbody tr:hover {
  background-color: #f9f9f9;
}

/* Scrollbar styling (optional) */
.top-picks-table-container::-webkit-scrollbar {
  width: 8px;
}

.top-picks-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.top-picks-table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.top-picks-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
